import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Link, useLocation, useNavigate, useParams } from '@reach/router'
import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../services/validate'
import { Alert } from '../../../components/Alert'
import { parse } from 'query-string'
// import { uploadApplicationFile } from '../../../services/files'

const voterForm = [
  {
    label: 'ID Number',
    name: 'idnumber',
    placeholder: 'ID Number',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateIdNumber()
        }
      }
    },
    type: 'text'
  },
  {
    label: 'Cellphone',
    name: 'cellphone',
    placeholder: 'Cellphone',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateCellphone()
        }
      }
    },
    type: 'text'
  }
]

export const RegistrationVoterForm: React.FC<{ idnumber?: string }> = (props) => {
  const navigate = useNavigate()
  const l = useLocation()
  const searchParams = parse(l.search)

  const [error, setError] = useState<string>('')
  const [fileLoad, setFileLoad] = useState('')
  const form = useForm({
    defaultValues: {
      idnumber: searchParams && searchParams.idnumber ? searchParams.idnumber : '',
      cellphone: ''
    }
  })
  const [createVoter, { loading, data }] = useMutation(CREATE_VOTER, {
    onCompleted: (res) => {
      if (res?.createVoter?.message === 'found') {
        navigate(`/voter/verify?idnumber=${res.createVoter.voter.idnumber}`)
      }
    },
    onError: (err) => {
      if (err) {
        setError(err.message)
      }
    }
  })

  const onRegister = () => {
    const { cellphone, idnumber } = form.getValues()
    createVoter({
      variables: {
        input: {
          cellphone,
          idnumber
        }
      }
    })
  }

  return (
    <Box>
      <form
        autoComplete="none"
        onSubmit={form.handleSubmit(onRegister)}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        {voterForm.map((field) => {
          const name = field.name as 'cellphone' | 'idnumber'

          return (
            <Box key={field.name}>
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
                {field.label}
              </label>
              <Input
                as="input"
                variant="standard"
                width="full"
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                ref={form.register(field.options)}
                className="mb-2 w-full"
              />
              {form.errors[name] && form.errors[name]?.type === 'required' ? (
                <div className="text-sm text-red-300">Required Field</div>
              ) : null}
              {form.errors[name] && form.errors[name]?.type === 'manual' ? (
                <div className="text-sm text-red-300">Invalid Field</div>
              ) : null}
            </Box>
          )
        })}

        <div className="sm:col-span-2 sm:flex sm:justify-start">
          <Button size="xl" variant="primary" type="submit" colorScheme="green">
            {loading ? 'Loading' : 'Register'}
          </Button>
        </div>
      </form>
      {error && (
        <Box className="mb-4">
          <Alert color="red" title="Error" message={error}>
            {error === 'User already registered.' ? (
              <Link to={`/verify/voter?idnumber=${form.getValues().idnumber}`}>
                <Button variant="primary" className="mb-2 text-center mt-4" colorScheme="green" type="submit">
                  View Voter - {form.getValues().idnumber}
                </Button>
              </Link>
            ) : null}
          </Alert>
        </Box>
      )}
    </Box>
  )
}

const CREATE_VOTER = gql`
  mutation createVoter($input: CreateVoterInput!) {
    createVoter(input: $input) {
      message
      voter {
        id
        idnumber
      }
    }
  }
`
