import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { ApplicationList } from './ ui/ApplicationList'

import { Box } from '@plusplusminus/plusplusdash'

export const Applications: React.FC = () => {
  const { data, loading } = useQuery(APPLICATIONS_QUERY)

  if (loading) return null

  return (
    <>
      <ApplicationList items={data.applications} />
      <Box className={'p-5'}></Box>
    </>
  )
}

const APPLICATIONS_QUERY = gql`
  query getApplications {
    applications {
      id
      owner {
        email
        firstName
        lastName
      }
      template {
        name
      }
      status
    }
  }
`
