import { Link } from '@reach/router'
interface ActionProps {
  login: React.ReactNode
}

type ActionKeys = keyof ActionProps

interface AlertProps {
  title: string
  message: string
  color: string
  action?: ActionKeys
  children?: React.ReactNode
}

export const Alert: React.FC<AlertProps> = ({ title = 'Error', message = '', color = 'red', action, children }) => {
  const actions: ActionProps = {
    login: (
      <Link
        to="/login"
        className={`block w-full bg-green border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-700`}
      >
        Login
      </Link>
    )
  }
  return (
    <div className={`rounded-md bg-${color}-100 p-4 mt-4`}>
      <div className="flex">
        <div className="ml-3">
          <h3 className={`text-lg font-medium text-${color}-800`}>{title}</h3>
          <div className={`mt-2 text-sm text-${color}-700`}>
            <p>{message}</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">{action ? actions[action] : null}</div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
