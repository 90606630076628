import React, { FC, useCallback, useState, useEffect } from 'react'
import { Link, RouteComponentProps, useNavigate } from '@reach/router'
import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { LayoutBox } from '../../components/LayoutBox'
import { useVoterLoginByOtp } from './hooks/useVoterLoginIdNumber'
import { OtpVerification } from '../Registration/ui/OtpVerification'
import { useOtpLogin } from '../../hooks/useOtpLogin'
import { LoginForm } from '../Login/ui/LoginForm'
import { useLogin } from '../../hooks/useLogin'
import { Alert } from '../../components/Alert'

interface VoterContainerProps extends RouteComponentProps {
  step: 'form'
}

export const VoterContainer: FC<VoterContainerProps> = (props) => {
  const navigate = useNavigate()
  const [user, setUser] = useState({
    token: ''
  })
  const [step, setStep] = useState(props.step)
  const [error, setError] = useState('')

  const onVerifySuccess = useCallback(() => {
    navigate('/voter/ballot')
  }, [])

  const onError = useCallback((err) => {
    alert(err)
    //alerts
  }, [])

  const form = useLogin({
    onSuccess: ({ data }: any) => {
      setStep(data.login.redirect)
      setUser({ token: data.login.token })
    },
    onError: () => {
      console.log('error')
    }
  })

  const otpIdNumberForm = useVoterLoginByOtp({
    onSuccess: ({ data }: any) => {
      setError('')
      setUser({ token: data.login.token })
      navigate('/voter/ballot')
    },
    onError: (error: any) => {
      setError(error.message)
    }
  })

  const otpForm = useOtpLogin({ onSuccess: onVerifySuccess, onError })

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'form':
        return (
          <>
            <LoginForm form={form} />
            <h2 className="mt-4 text-sm leading-6 font-medium text-gray-900">Physical Voting Stations</h2>
            <a
              className="mt-2 inline-flex items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-white bg-green hover:bg-green-500"
              href="https://www.actionsa.org.za/where-to-vote/"
              target="_blank"
            >
              Where to vote in person?
            </a>
            <h2 className="mt-4 text-sm leading-6 font-medium text-gray-900">Not Registered?</h2>
            <Link
              to="/register/voter"
              className="mt-2 inline-flex items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-white bg-green hover:bg-green-500"
            >
              Register
            </Link>
            <div className="mt-8">
              <dt className="text-sm font-medium text-gray-900">Need assistance?</dt>
              <dd className="text-sm text-gray-500">
                Email <a href="mailto:vote@actionsa.org.za">vote@actionsa.org.za</a>.
              </dd>
            </div>
          </>
        )
      case 'otp':
        return <OtpVerification form={otpForm} token={user.token} />
      case 'idnumber':
        return <LoginOtpForm form={otpIdNumberForm} token={user.token} error={error} />
      case 'emailConfirm':
        return <h3 className="text-lg font-medium text-dark mb-4 text-center">Check your email for login link.</h3>
    }
  }

  return (
    <LayoutBox
      title="Thank you for registering to participate in the ActionSA Candidate Elections."
      description={'Please login to cast your vote for your preferred Ward Councillor and Mayoral candidates.'}
      formTitle={'To vote, login with your registered ID Number and Cellphone'}
      showLogo={true}
    >
      <Box className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 my-auto">{getWizardComponent(step)}</Box>
    </LayoutBox>
  )
}

export const LoginOtpForm: React.FC<any> = (props) => {
  const { register, handleSubmit, errors, onSubmit, submitting } = props.form

  useEffect(() => {
    props.form.setValue('token', props.token)
  }, [props.token])

  return (
    <form autoComplete="no" onSubmit={handleSubmit(onSubmit)}>
      {props.error ? <Alert title={'Error'} color="red" message={props.error} action={undefined}></Alert> : null}
      <p className="my-4 text-base max-w-3xl">Enter your ID Number and Pin Provided.</p>
      <Box className="mb-4">
        <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
          South African ID Number
        </label>
        <Input
          as="input"
          variant="standard"
          width="md"
          name="idnumber"
          placeholder="Enter your ID number"
          ref={register({ required: true })}
          className="mb-2 w-full"
        />
        {errors.idnumber && <div className="text-sm text-red-300">{errors.idnumber.message}</div>}
      </Box>
      <Box className="mb-4">
        <label htmlFor={'otp'} className="block text-sm font-medium text-gray-900 mb-1">
          6 Digit Pin
        </label>
        <Input
          as="input"
          variant="standard"
          width="md"
          name="otp"
          placeholder="Enter your 6 digit pin"
          ref={register({ required: true })}
          className="mb-2 w-full"
        />
        {errors.otp && <div className="text-sm text-red-300">{errors.otp.message}</div>}
      </Box>
      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <Button size="xl" variant="primary" type="submit" colorScheme="green" className="mt-2 " disabled={submitting}>
          {submitting ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}
