import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { useLogin } from '../../../hooks/useLogin'

interface Props {
  form?: any
}

export const LoginForm: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors, onSubmit, submitting } = props.form
  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <Box className="mb-4">
        <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
          ID Number
        </label>
        <Input
          as="input"
          variant="standard"
          width="md"
          name="idnumber"
          placeholder="ID Number"
          type="text"
          ref={register({ required: true })}
          className="mb-2 w-full"
        />
        {errors.idnumber && <div className="text-sm text-red-300">{errors.idnumber.message}</div>}
      </Box>
      <Box className="mb-4">
        <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
          Cellphone
        </label>
        <Input
          as="input"
          variant="standard"
          width="md"
          name="contact"
          placeholder="Cellphone"
          ref={register({ required: true })}
          className="mb-2 w-full"
        />
        {errors.contact && <div className="text-sm text-red-300">{errors.contact.message}</div>}
      </Box>

      <Button size="xl" variant="primary" className="w-full " colorScheme="green" type="submit" disabled={submitting}>
        {submitting ? 'Loading...' : 'Login'}
      </Button>
    </form>
  )
}
