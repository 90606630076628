import { FC } from 'react'
import { RouteComponentProps, useLocation } from '@reach/router'
import { parse } from "query-string"
import { Layout } from '../../components/Layout'
import { AddressDetails } from './AddressDetails'
import { ProfileDetails } from './ProfileDetails'
import { useUserQuery } from '../../hooks/useUserQuery'
export const Profile: FC<RouteComponentProps> = () => {
  const location = useLocation()
  const searchParams = parse(location.search)
  const { loading, data } = useUserQuery()
  if (loading) return null

  const hasEditPermission = data.me && data.me.role === 'ADMIN'

  return (
    <Layout>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-100">
        <div className="px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Profile</h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4"></div>
        </div>
        <section aria-labelledby="profile_details_heading">
          <ProfileDetails data={data.me} hasEditPermission={hasEditPermission} isUser={true} role={data?.me?.role || "USER"} searchParams={searchParams}/>
        </section>
        <section aria-labelledby="address_details_heading">
          <AddressDetails data={data.me.address} isUser={true} />
        </section>
      </main>
    </Layout>
  )
}
