import React, { useEffect, useState } from 'react'
import { Redirect, RouteComponentProps, useParams } from '@reach/router'
import { useNavigate } from '@reach/router'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const VerifyEmail: React.FC<RouteComponentProps> = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [confirm] = useMutation(CONFIRM_EMAIL_ADDRESS)

  useEffect(() => {
    confirm({
      variables: {
        confirmationToken: id
      }
    })
      .then(() => {
        navigate('/profile?emailConfirmed=true')
      })
      .catch((err) => {
        navigate('/login')
      })
  }, [])

  return <div>Please wait...</div>
}

const CONFIRM_EMAIL_ADDRESS = gql`
  mutation confirmEmail($confirmationToken: String!) {
    confirmEmail(confirmationToken: $confirmationToken) {
        message
    }
  }
`
