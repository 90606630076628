import { useState } from 'react'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { Alert } from '../../Alert'

export const RoleDetails = ({ data }: any) => {
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const { onSubmit } = useProfileUpdate({
    onSuccess: (p: any) => {
      setMessage('Profile successfully saved.')
    },
    onError: (p: any) => {
      setMessage('')
      setError('Error saving profile')
    },
    isUser: false
  })

  const onFormSubmit = (value: any) => {
    setMessage('')
    setError('')
    const update = { role: value }

    onSubmit({
      ...update,
      id: data.id
    })
  }

  return (
    <div className="mx-6 mb-6">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
              Role
            </h2>
            <p className="mt-1 text-sm text-gray-500">Change user role.</p>
          </div>

          <div className="mt-6 grid grid-cols-4 gap-6">
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="ADMIN"
                  className="form-radio h-4 w-4"
                  name="radio-sizes"
                  onChange={async (e) => {
                    onFormSubmit(e.target.value)
                  }}
                  defaultChecked={data.role === 'ADMIN'}
                />
                <span className="ml-2">Admin</span>
              </label>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-4 gap-6">
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="ELECTION"
                  className="form-radio h-4 w-4"
                  name="radio-sizes"
                  onChange={async (e) => {
                    onFormSubmit(e.target.value)
                  }}
                  defaultChecked={data.role === 'ELECTION'}
                />
                <span className="ml-2">ELECTION OFFICER</span>
              </label>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-4 gap-6">
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="USER"
                  className="form-radio h-4 w-4"
                  name="radio-sizes"
                  onChange={async (e) => {
                    onFormSubmit(e.target.value)
                  }}
                  defaultChecked={data.role === 'USER'}
                />
                <span className="ml-2">User</span>
              </label>
            </div>
          </div>

          {message ? <Alert title={'Success'} message={message} action={undefined} color="green" /> : null}
          {error ? <Alert title={'Error'} message={error} action={undefined} color="red" /> : null}
        </div>
      </div>
    </div>
  )
}
