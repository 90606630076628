import { navigate, RouteComponentProps } from '@reach/router'
import { Button, Loader } from '@plusplusminus/plusplusdash'
import { LayoutBox } from '../../../components/LayoutBox'
import { gql, useMutation } from '@apollo/client'
import { useEffect } from 'react'

const LOGOUT = gql`
  mutation logout {
    logout
  }
`

export const BallotSuccess: React.FC<RouteComponentProps> = () => {
  const [logout, { loading }] = useMutation(LOGOUT, {
    onCompleted: () => {
      navigate('/')
    }
  })

  useEffect(() => {
    setTimeout(() => {
      logout()
    }, 10000)
  })
  return (
    <LayoutBox
      title={`Success!`}
      description={''}
      formTitle="Your vote has been successfully submitted."
      showLogo={true}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="my-6">
            <Button
              size="xl"
              variant="primary"
              colorScheme="green"
              onClick={() => {
                logout()
              }}
            >
              Log out
            </Button>
          </div>
        </>
      )}
    </LayoutBox>
  )
}
