import { React } from '@ungap/global-this'

interface Props {
  title: string
  actions: Array<React.ReactNode>
}

export const HeaderWithActions: React.FC<Props> = ({ title = 'Home', actions = [] }) => {
  return (
    <div className="px-4 py-4 border-b sm:flex sm:items-center sm:justify-between sm:px-6">
      <div className="flex-1 min-w-0">
        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">{title}</h1>
      </div>
      <div className="mt-4 flex sm:mt-0 sm:ml-4">{actions ? actions : null}</div>
    </div>
  )
}
