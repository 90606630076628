import { useState, useEffect } from 'react'
import { Box, Input, Button } from '@plusplusminus/plusplusdash'
import { useProfileUpdate } from '../../hooks/useProfileUpdate'
import { Alert } from '../../components/Alert'
import { InputValidator } from '../../services/validate'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: true
    },
    permission: ['ADMIN']
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: true
    },
    type: 'text',
    permission: ['ADMIN']
  },
  {
    label: 'ID Number',
    name: 'idnumber',
    placeholder: 'ID Number',
    disabled: true,
    options: {
      required: true
    },
    type: 'text',
    permission: ['NOT_ALLOWED']
  },
  {
    label: 'Cellphone',
    name: 'cellphone',
    placeholder: 'Cellphone',
    type: 'text',
    permission: ['ADMIN']
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {},
    type: 'email',
    permission: ['ADMIN', 'USER']
  }
]

interface ProfileDetailsProps {
  data: any
  hasEditPermission?: boolean
  isUser?: boolean
  role: string
  searchParams?: { emailConfirmed?: boolean }
}

export const ProfileDetails = ({ data, hasEditPermission, isUser, role, searchParams }: ProfileDetailsProps) => {
  const [disabled, setDisabled] = useState(true)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [emailConfirmationAlert, setEmailConfirmationAlert] = useState(false)

  const { register, handleSubmit, errors, onSubmit, submitting } = useProfileUpdate({
    onSuccess: (p: any) => {
      setDisabled(true)
      setError('')
      if (role === 'USER') {
        setMessage(
          'Confirmation email has been sent to the new email address. Please click the link in email to confirm email address.'
        )
      } else {
        setMessage('Profile successfully saved.')
      }
    },
    onError: (p: any) => {
      setMessage('')
      setError('Error saving profile')
    },
    isUser
  })

  useEffect(() => {
    if (searchParams?.emailConfirmed) {
      setEmailConfirmationAlert(true)
    }
  }, [])

  const onFormSubmit = (values: any) => {
    const update = { ...values }
    if (values.cellphone) {
      const cellphoneInput = new InputValidator(values.cellphone)

      if (!cellphoneInput.validateCellphone()) {
        setError('Invalid cellphone number')
        return
      } else {
        update.cellphone = cellphoneInput.formatCellphone('E.164')
      }
    }

    if (values.email) {
      const emailInput = new InputValidator(values.email)

      if (!emailInput.validateEmail()) {
        setError('Invalid cellphone number')
        return
      } else {
        update.email = values.email.trim()
      }
    }

    isUser
      ? onSubmit(update)
      : onSubmit({
          ...update,
          id: data.id
        })
  }

  return (
    <div className="mx-6 mb-6">
      <form autoComplete={'off'} onSubmit={handleSubmit(onFormSubmit)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
                Profile Details
              </h2>
              <p className="mt-1 text-sm text-gray-500">Update profile information.</p>
            </div>
            {emailConfirmationAlert ? (
              <Alert
                title={'Success'}
                message="Your email has been updated successfully"
                action={undefined}
                color="green"
              />
            ) : null}
            {message ? <Alert title={'Success'} message={message} action={undefined} color="green" /> : null}
            {error ? <Alert title={'Error'} message={error} action={undefined} color="red" /> : null}
            <div className="mt-6 grid grid-cols-4 gap-6">
              {form.map((field) => {
                const { name, type, options, placeholder, label, disabled: forceDisabled, permission, ...rest } = field

                if (type === 'hidden') {
                  return <input type="hidden" ref={register(options)} name={name} />
                }

                const hasPermission = !disabled && permission.includes(role)

                return (
                  <Box className="col-span-4 sm:col-span-2">
                    <label htmlFor={name} className="block text-sm font-medium text-gray-900 mb-1">
                      {label}
                    </label>
                    <Input
                      disabled={!hasPermission}
                      as="input"
                      variant="standard"
                      width="full"
                      name={name}
                      type={type}
                      defaultValue={data[name]}
                      placeholder={placeholder}
                      ref={register(options)}
                      className={`w-full ${!hasPermission || forceDisabled ? 'bg-gray-200' : 'white'}`}
                      {...rest}
                    />
                    {errors[name] && <div className="text-sm text-red-300">Required Field</div>}
                  </Box>
                )
              })}
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <ToolBelt
              hasEditPermission={hasEditPermission}
              disabled={disabled}
              setDisabled={setDisabled}
              role={role}
              loading={submitting}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

const ToolBelt = ({ hasEditPermission, disabled, setDisabled, role, loading }: any) => {
  if (role === 'USER') {
    return (
      <>
        {!disabled && (
          <Button
            variant="primary"
            className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mx-2"
            colorScheme="black"
            type="submit"
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        )}
        <Button
          variant="primary"
          className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mx-2"
          colorScheme="green"
          onClick={(e) => {
            e.preventDefault()
            setDisabled(!disabled)
          }}
        >
          Edit email
        </Button>
      </>
    )
  }

  if (role === 'ADMIN') {
    return (
      <>
        {!disabled && (
          <Button
            variant="primary"
            className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mx-2"
            colorScheme="black"
            type="submit"
          >
            Save
          </Button>
        )}
        <Button
          variant="primary"
          className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mx-2"
          colorScheme="green"
          onClick={(e) => {
            e.preventDefault()
            setDisabled(!disabled)
          }}
        >
          Edit
        </Button>
      </>
    )
  }

  return null
}
