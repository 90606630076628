import React, { useEffect } from 'react'
import { RouteComponentProps, useParams } from '@reach/router'
import { useNavigate } from '@reach/router'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const ActivateLink: React.FC<RouteComponentProps> = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [verify] = useMutation(ACTIVATE_TOKEN_VERIFICATION)

  useEffect(() => {
    verify({
      variables: {
        id: id
      }
    })
      .then(() => {
        navigate('/voter/ballot')
      })
      .catch(() => {
        navigate('/')
      })
  }, [])

  return <div>Please wait...</div>
}

const ACTIVATE_TOKEN_VERIFICATION = gql`
  mutation activateAccount($id: String!) {
    verify: activateAccount(activationLinkId: $id) {
      token
    }
  }
`
