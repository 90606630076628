import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const userQueryGQL = gql`
  query user {
    me {
      id
      firstName
      lastName
      cellphone
      idnumber
      email
      role
      address {
        id
        streetNumber
        addressLine1
        addressLine2
        city
        suburb
        country
        state
        lat
        lng
        zipCode
      }
    }
  }
`

const queryById = gql`
  query getUser($id: String!) {
    userById(id: $id) {
      id
      firstName
      lastName
      cellphone
      email
      idnumber
      role
      address {
        id
        streetNumber
        addressLine1
        addressLine2
        city
        suburb
        country
        state
        lat
        lng
        zipCode
      }
    }
  }
`

export const useUserQuery = (id?: string) =>
  id ? useQuery(queryById, { variables: { id: id } }) : useQuery(userQueryGQL)
