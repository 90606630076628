import { Box } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { Alert } from '../../components/Alert'
import { LayoutBox } from '../../components/LayoutBox'

export const CandidateConfirmation: React.FC<RouteComponentProps> = () => {
  return (
    <LayoutBox
      title={'Candidate Registration'}
      formTitle="Thank you for registering as a candidate for the upcoming ActionSA Candidate Elections."
      description={'Follow the following instructions to complete your ActionSA candidate application.'}
      showLogo={true}
    >
      <Box className="mt-3">
        <Box className="mb-3">
          <Alert
            title="Success"
            message="Follow the following instructions to complete your ActionSA candidate application."
            color="green"
          ></Alert>
        </Box>
        <dl>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              1. Check your email for a registration activation email
            </dt>
            <dd className="text-base text-gray-500">
              Click the "Activate Account" button in the email to continue the registration process.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              2. View your candidate application details once the account is activated.
            </dt>
            <dd className="text-base text-gray-500">
              Upon activation you will be directed to your application form. Please complete all the required
              information on the application form.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              3. If you are running as a Ward Councillor Candidate
            </dt>
            <dd className="text-base text-gray-500">
              Add the desired wards to your application Once your wards have been allocated, a ward petition will be
              created for each ward
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">4. Upload the required documentation</dt>
            <dd className="text-base text-gray-500">
              Application documentation requirements are listed on your application.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">5. Sign the declaration</dt>
            <dd className="text-base text-gray-500">Make sure you meet all the requirements stipulated on the form</dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">6. View your ward petitions</dt>
            <dd className="text-base text-gray-500">
              You will need to secure a minimum of 500 endorsements from each ward in which you wish to stand.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">7. Submit application</dt>
            <dd className="text-base text-gray-500">
              Your application will be reviewed to ensure you meet all the requirements.
            </dd>
          </div>
          <div className="mt-8">
            <dt className="text-sm font-medium text-gray-900">Need assistance?</dt>
            <dd className="text-sm text-gray-500">
              Email <a href="mailto:vote@actionsa.org.za">vote@actionsa.org.za</a>.
            </dd>
          </div>
        </dl>
      </Box>
    </LayoutBox>
  )
}
