import { Box, Button, Checkbox, Input } from '@plusplusminus/plusplusdash'
import { validate } from 'graphql'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../services/validate'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: true
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    label: 'Cellphone',
    name: 'cellphone',
    placeholder: 'Cellphone',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateCellphone()
        }
      }
    },
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      validate: {
        manual: (value: string) => {
          if (value) {
            return new InputValidator(value).validateEmail()
          }

          return true
        }
      }
    },
    type: 'email'
  },
  {
    label: 'ID Number',
    name: 'idnumber',
    placeholder: 'ID Number',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateIdNumber()
        }
      }
    },

    type: 'text'
  },
  {
    label: 'I endorse this candidate to stand for election.',
    name: 'nominate',
    placeholder: 'Nominate',
    options: {
      required: true
    },
    type: 'checkbox'
  },
  {
    label: 'I would like to register as a voter in the upcoming ActionSA Candidate Elections',
    name: 'register',
    placeholder: 'Register',
    options: {},
    type: 'checkbox'
  }
]

interface Props {
  onSubmit?: (data: any) => void
}

export const PetitionForm: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors } = useForm({})

  const onSubmit = async (data: any) => {
    if (props.onSubmit) {
      props.onSubmit(data)
    }
  }

  return (
    <form autoComplete="none" onSubmit={handleSubmit(onSubmit)} className="mt-6 grid grid-cols-4 gap-6">
      {form.map((field) => {
        if (field.type === 'checkbox') {
          return (
            <Box className="flex items-center col-span-2 col-span-4">
              <input
                id={field.name}
                name={field.name}
                type="checkbox"
                ref={register(field.options)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={field.name} className="ml-2 block text-sm text-gray-900">
                {field.label}
              </label>
            </Box>
          )
        }
        return (
          <Box className="col-span-4 sm:col-span-2">
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
              {field.label}
            </label>
            <Input
              as="input"
              variant="standard"
              width="full"
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              ref={register(field.options)}
              className="w-full"
            />
            {errors[field.name] && errors[field.name].type === 'required' ? (
              <div className="text-sm text-red-300">Required Field</div>
            ) : null}
            {errors[field.name] && errors[field.name].type === 'manual' ? (
              <div className="text-sm text-red-300">Invalid Field</div>
            ) : null}
          </Box>
        )
      })}

      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <Button size="xl" variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  )
}
