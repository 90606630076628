import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Layout } from '../../Layout'
import { HeaderWithActions } from '../../../components/Header/HeaderWithActions'
import { Box, Table, Loader, Alert } from '@plusplusminus/plusplusdash'
import { usePagination } from '../../../hooks/usePagaination'

interface Props {
  petitionId: string
}

const TABLE_SHAPE = [
  { label: 'First name', key: 'firstName', isSortable: true },
  { label: 'Last name', key: 'lastName', isSortable: true },
  { label: 'Email', key: 'email', isSortable: true },
  { label: 'Cellphone', key: 'cellphone', isSortable: true },
  { label: 'ID number', key: 'idnumber', isSortable: true },
  { label: 'Nominated', key: 'nominated', isSortable: true },
  { label: 'Voter', key: 'voter', isSortable: true }
]

export const PetitionSubmissions: React.FC<Props> = ({ petitionId }) => {
  const [page, offset, limit, nextPage, prevPage] = usePagination(50)
  const { data, loading, error, refetch, previousData } = useQuery(GET_SUBMISSIONS, {
    variables: { id: petitionId, pagination: { limit, sort: 'ASC', page } }
  })

  if (loading) return null

  const tableData = data || previousData

  return (
    <Layout>
      <Box className="flex flex-col">
        <HeaderWithActions title="Submissions" actions={[]} />
        {error ? (
          <Alert type="error">
            <Alert.Heading>There was an error fetching submissions</Alert.Heading>
          </Alert>
        ) : !previousData && loading ? (
          <div className="flex flex-auto justify-center items-center">
            <Loader isActive={true} />
          </div>
        ) : tableData ? (
          <Box>
            <Box>
              <Table shape={TABLE_SHAPE}>
                {tableData.submissions.docs.map((submission: any) => {
                  return (
                    <Table.Row key={submission.id}>
                      <Table.Cell className="font-medium text-gray-900">{submission.firstName}</Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{submission.lastName}</Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{submission.email}</Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{submission.cellphone}</Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{submission.idnumber}</Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{submission.voter ? 'Yes' : 'No'}</Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">
                        {submission.nominate ? 'Yes' : 'No'}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table>
            </Box>
            <div className="flex justify-end py-4 px-8">
              <div className="w-16">
                {tableData.submissions.hasPrevPage && (
                  <a className="underline text-md cursor-pointer" onClick={() => prevPage()}>
                    Prev
                  </a>
                )}
              </div>
              <span className="w-16 text-md mx-4">Page {page}</span>
              <div className="w-16">
                {tableData.submissions.hasNextPage && (
                  <a className="underline text-md cursor-pointer" onClick={() => nextPage()}>
                    Next
                  </a>
                )}
              </div>
            </div>
          </Box>
        ) : null}
      </Box>
    </Layout>
  )
}

export const GET_SUBMISSIONS = gql`
  query getSubmissions($id: String!, $pagination: PaginationArgs!) {
    submissions(id: $id, pagination: $pagination) {
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
      docs {
        id
        firstName
        lastName
        email
        idnumber
        cellphone
        nominate
        voter
      }
    }
  }
`
