import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../services/validate'

export const useProfileUpdate = ({ onSuccess, onError, isUser = true }: any) => {
  const form = useForm()

  // auth mutation here
  const [updateProfile, { loading }] = useMutation(isUser ? UPDATE_PROFILE : UPDATE_USER)

  const onSubmit = useCallback(
    async (data) => {
      const update = { ...data }

      if (data.address) {
        if (!!data.address.lng) {
          update.address.lng = parseFloat(data.address.lng)
        }
        if (!!data.address.lat) {
          update.address.lat = parseFloat(data.address.lat)
        }
      }

      try {
        const response = await updateProfile({ variables: { input: update } })
        onSuccess(response)
      } catch (err) {
        onError(err.message)
      }
    },
    [updateProfile, onSuccess, onError, form]
  )

  return {
    ...form,
    onSubmit,
    submitting: loading
  }
}

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateUserInput!) {
    updateProfile(input: $input) {
      id
      firstName
      lastName
      cellphone
      idnumber
      email
      address {
        id
        streetNumber
        addressLine1
        addressLine2
        city
        suburb
        country
        lat
        lng
        zipCode
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser($input: AdminUpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      cellphone
      idnumber
      email
      address {
        id
        streetNumber
        addressLine1
        addressLine2
        city
        suburb
        country
        lat
        lng
        zipCode
      }
    }
  }
`
