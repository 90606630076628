import { FC, useCallback, useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Box } from '@plusplusminus/plusplusdash'
import logo from '../../logo.png'
import { useLogin } from '../../hooks/useLogin'
import { LoginForm } from './ui/LoginForm'
import { OtpVerification } from '../Registration/ui/OtpVerification'
import { useOtpLogin } from '../../hooks/useOtpLogin'

interface LoginData {
  contact: string
  idnumber: string
}
interface LoginProps extends RouteComponentProps {
  redirect?: string
}
export const Login: FC<LoginProps> = ({ redirect = '/voter/ballot' }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState({
    token: ''
  })
  const [step, setStep] = useState('form')

  const onVerifySuccess = useCallback(() => {
    navigate(redirect)
  }, [])

  const onError = useCallback((err) => {
    alert(err.message)
    //alerts
  }, [])

  const otpForm = useOtpLogin({ onSuccess: onVerifySuccess, onError })
  const form = useLogin({
    onSuccess: ({ data }: any) => {
      setStep(data.login.redirect)
      setUser({ token: data.login.token })
    },
    onError: () => {
      console.log('error')
    }
  })

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'form':
        return <LoginForm form={form} />
      case 'otp':
        return <OtpVerification form={otpForm} token={user.token} />
      case 'emailConfirm':
        return <h3 className="text-lg font-medium text-dark mb-4 text-center">Check your email for login link.</h3>
    }
  }

  return (
    <Box className="min-h-screen bg-green flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Box className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-100 w-auto" src={logo} alt="ActionSA" />
        <h2 className="mt-6 text-center text-3xl font-bold text-white">Sign in to your account</h2>
      </Box>
      <Box className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Box className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{getWizardComponent(step)}</Box>
      </Box>
    </Box>
  )
}
