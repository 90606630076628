import { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from '../../components/Layout'
import { ReportList } from './ui/ReportList'
import { HeaderWithActions } from '../../components/Header/HeaderWithActions'

export const ReportsContainer: FC<RouteComponentProps> = () => {
    return (
        <Layout>
            <HeaderWithActions title="Reports" actions={[]} />
            <ReportList />
        </Layout>
    )
}



