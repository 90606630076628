import { Box, Input } from '@plusplusminus/plusplusdash'
import { useEffect, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { Alert } from '../../components/Alert'
import { AddressState, useAddressSearch } from '../../hooks/useAddressSearch'
import { useProfileUpdate } from '../../hooks/useProfileUpdate'

export const form = [
  {
    key: 'streetNumber',
    label: 'Street Number',
    name: 'address.streetNumber',
    placeholder: 'Street Number',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'addressLine1',
    label: 'Address Line 1',
    name: 'address.addressLine1',
    placeholder: 'Address Line 1',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'addressLine2',
    label: 'Address Line 2',
    name: 'address.addressLine2',
    placeholder: 'Address Line 2',
    options: {},
    type: 'text'
  },
  {
    key: 'suburb',
    label: 'Suburb',
    name: 'address.suburb',
    placeholder: 'Suburb',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'city',
    label: 'City',
    name: 'address.city',
    placeholder: 'City',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'state',
    label: 'Province',
    name: 'address.state',
    placeholder: 'Province',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'zipCode',
    label: 'Postal Code',
    name: 'address.zipCode',
    placeholder: 'Postal Code',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'country',
    label: 'Country',
    name: 'address.country',
    placeholder: 'Country',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    key: 'lat',
    label: 'Latitude',
    name: 'address.lat',
    options: {},
    type: 'hidden'
  },
  {
    key: 'lng',
    label: 'Longitude',
    name: 'address.lng',
    options: {},
    type: 'hidden'
  },
  {
    key: 'placeId',
    label: 'Place ID',
    name: 'address.placeId',
    options: {},
    type: 'hidden'
  }
]

export const AddressDetails = ({ id, data, isUser = true }: any) => {
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const { register, handleSubmit, errors, setValue, onSubmit } = useProfileUpdate({
    onSuccess: (p: any) => {
      setError('')
      setMessage('Profile successfully saved.')
    },
    onError: (p: any) => {
      setMessage('')
      setError('Error saving profile')
    },
    isUser
  })

  const onFormSubmit = (values: any) => {
    const update = { ...values }

    isUser
      ? onSubmit(update)
      : onSubmit({
          ...update,
          id: id
        })
  }

  const { query, addressState, handleAddressSelect } = useAddressSearch(data)

  useEffect(() => {
    if (addressState) {
      Object.keys(addressState).forEach((key) => {
        setValue(`address.${key}`, addressState[key as keyof AddressState])
      })
    }
  }, [addressState])

  return (
    <div className="mx-6 mb-6">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
                Address Details
              </h2>
              <p className="mt-1 text-sm text-gray-500">Update residential address.</p>
            </div>
            {message ? <Alert title={'Success'} message={message} action={undefined} color="green" /> : null}
            {error ? <Alert title={'Error'} message={error} action={undefined} color="red" /> : null}
            <div className="mt-6 grid grid-cols-4 gap-6">
              <Box className="col-span-8 sm:col-span-4">
                <label htmlFor={'location'} className="block text-sm font-medium text-gray-900 mb-1">
                  Address
                </label>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAbSui3xvIK1mhbFzgrDb408aqVIQN4xxs"
                  selectProps={{
                    value: query,
                    onChange: async (locationResult: any) => {
                      await handleAddressSelect(locationResult.label)
                    }
                  }}
                />
              </Box>

              {form.map((field) => {
                const { key, name, type, options, placeholder, label, ...rest } = field

                if (type === 'hidden') {
                  return (
                    <input
                      type="hidden"
                      ref={register(options)}
                      name={name}
                      defaultValue={data ? data[name.split('.')[1]] : ''}
                    />
                  )
                }

                return (
                  <Box className="col-span-4 sm:col-span-2">
                    <label htmlFor={name} className="block text-sm font-medium text-gray-900 mb-1">
                      {label}
                    </label>

                    <Input
                      key={key}
                      as="input"
                      variant="standard"
                      width="full"
                      name={name}
                      type={type}
                      placeholder={placeholder}
                      ref={register(options)}
                      className="w-full"
                      {...rest}
                      defaultValue={data ? data[key] : ''}
                    />
                    {errors.address && errors.address[name.split('.')[1]] && (
                      <div className="text-sm text-red-300">Required Field</div>
                    )}
                  </Box>
                )
              })}
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
