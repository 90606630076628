import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { useEffect } from 'react'
import { ResendOtp } from './ResendOtp'

const fields = [
  {
    label: 'One Time Pin (OTP)',
    name: 'otp',
    type: 'text',
    placeholder: 'Enter one time pin',
    options: {
      required: true
    }
  },
  {
    label: null,
    name: 'token',
    type: 'hidden',
    options: {
      required: true
    }
  }
]

interface Props {
  onSubmit?: (data: any) => void
  form?: any
  token?: string
}

export const OtpVerification: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors, onSubmit, submitting } = props.form

  useEffect(() => {
    props.form.setValue('token', props.token)
  }, [props.token])

  return (
    <form autoComplete="no" onSubmit={handleSubmit(onSubmit)}>
      <p className="my-4 text-base max-w-3xl">
        A One Time Pin (OTP) has been sent via SMS to the cellphone number you provided for registration. Please enter
        this OTP to verify your cellphone number.
      </p>
      {fields.map((field) => {
        if (field.type === 'hidden') {
          return <input type={'hidden'} ref={register(field.options)} name={field.name} />
        }

        return (
          <Box>
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
              {field.label}
            </label>
            <Input
              as="input"
              variant="standard"
              width="full"
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              ref={register(field.options)}
              className="mb-2 w-full"
            />
            {errors[field.name] && <div className="text-sm text-red-300">{errors[field.name].message}</div>}
          </Box>
        )
      })}
      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <Button size="xl" variant="primary" type="submit" colorScheme="green" className="mt-2 " disabled={submitting}>
          {submitting ? 'Loading...' : 'Submit'}
        </Button>
        <ResendOtp token={props.token} />
      </div>
    </form>
  )
}
