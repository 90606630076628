import { Box, Button } from '@plusplusminus/plusplusdash'
import { Link, RouteComponentProps, useParams } from '@reach/router'
import { Alert } from '../../components/Alert'
import { LayoutBox } from '../../components/LayoutBox'

export const RegisteredConfirmation: React.FC<RouteComponentProps> = () => {
  const { petitionId } = useParams()
  return (
    <LayoutBox
      title={'Voter Registration'}
      formTitle="Thank you for registering as a Voter for the ActionSA Candidate Elections."
      description={'We please need you to complete the following steps to finalise your registration.'}
      showLogo={true}
    >
      <Box className="mt-3">
        <Box className="mb-3">
          <Alert
            title="Success"
            message="We please need you to complete the following steps to finalise your registration."
            color="green"
          >
            {petitionId ? (
              <Link to={`/petition/${petitionId}`}>
                <Button variant="primary" size="md" className="mt-2">
                  Start Again
                </Button>
              </Link>
            ) : null}
          </Alert>
        </Box>
        <dl>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              1. If you provided an email address during the registration process
            </dt>
            <dd className="text-base text-gray-500">
              Please verify your email address using the link we have just sent you. Click the "Activate Account" button
              in the email to continue the registration process.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              2. If you did not provide an email address during the registration process
            </dt>
            <dd className="text-base text-gray-500">
              Please access your voter application form using the One Time Pin (OTP) sent to the cellphone number you
              provided. Click the “Login” button below to continue the registration process.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              3. Upon activation you will be directed to your voter application form
            </dt>
            <dd>You can review your details and upload the required documents.</dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              4. Next you will need to upload a copy of your Identity Document to finalise your registration.
            </dt>
            <dd className="text-base text-gray-500">
              Click the “Upload file” button next to the Identity Document tab.
            </dd>
          </div>
          <div className="mb-4">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              5. Once you have uploaded your Identity Document, submit your application.
            </dt>
            <dd className="text-base text-gray-500">Click the “Submit” button in the top right-hand corner.</dd>
          </div>
        </dl>
      </Box>
    </LayoutBox>
  )
}
