import { useCallback, useEffect, useState } from 'react'
import { geocodeByAddress } from 'react-google-places-autocomplete'

export interface AddressState {
  streetNumber?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  suburb?: string
  state?: string
  zipCode?: string
  country?: string
  lat?: number
  lng?: number
  placeId?: string
}

export type useAddressSearch = ReturnType<typeof useAddressSearch>
export type searchKeys = { [key: string]: keyof AddressState }

const formFields: { [key: string]: keyof google.maps.GeocoderAddressComponent } = {
  street_number: 'short_name',
  route: 'long_name', // addressLine1
  administrative_area_level_1: 'short_name', // state
  locality: 'long_name', // city
  postal_code: 'short_name', // zipCode
  sublocality_level_1: 'short_name', //suburb
  sublocality: 'short_name',
  country: 'short_name'
}

export const useAddressSearch = (init: AddressState) => {
  const [query, setQuery] = useState('')
  const [error, setError] = useState('')

  const [addressState, setAddressState] = useState<AddressState>(
    init || {
      streetNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      suburb: '',
      zipCode: '',
      country: '',
      lat: 0,
      lng: 0,
      placeId: ''
    }
  )

  const handleQueryChange = useCallback(
    (value: string) => {
      if (error) setError('')
      setQuery(value)
    },
    [error]
  )

  const handleAutocompleteError = useCallback((status: string, clearSuggestions: () => void) => {
    if (status === 'ZERO_RESULTS') {
      setError("Hi, seems like we can't autocomplete your address, Please try again.")
      clearSuggestions()
    }

    clearSuggestions()
  }, [])

  const handleAddressChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { name, value } }) => {
      setAddressState({
        ...addressState,
        [name]: value
      })
    },
    [addressState]
  )

  const handleAddressSelect = useCallback(
    async (value: string) => {
      const [place] = await geocodeByAddress(value)
      const fields = place.address_components.reduce((acc, cur) => {
        cur.types.forEach((addressType) => {
          if (formFields[addressType]) {
            const val = cur[formFields[addressType]]
            if (val instanceof Array) {
              acc[addressType] = val.join(' ')
            } else {
              acc[addressType] = val
            }
          }
        })

        return acc
      }, {} as { [key: string]: string })

      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()

      setQuery(value)

      setAddressState({
        ...addressState,
        streetNumber: fields.street_number,
        addressLine1: fields.route,
        city: fields.locality || '',
        suburb: fields.sublocality_level_1 || fields.sublocality,
        state: fields.administrative_area_level_1 || '',
        zipCode: fields.postal_code || '',
        country: fields.country || '',
        placeId: place.place_id,
        lat,
        lng
      })

      setError('')
    },
    [addressState]
  )

  const handlePlaceSelect = useCallback(
    async (place: google.maps.GeocoderResult) => {
      const fields = place.address_components.reduce((acc, cur) => {
        cur.types.forEach((addressType) => {
          if (formFields[addressType]) {
            const val = cur[formFields[addressType]]
            if (val instanceof Array) {
              acc[addressType] = val.join(' ')
            } else {
              acc[addressType] = val
            }
          }
        })

        return acc
      }, {} as { [key: string]: string })

      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()

      setAddressState({
        ...addressState,
        streetNumber: fields.street_number,
        addressLine1: fields.route,
        city: fields.locality || '',
        suburb: fields.sublocality_level_1 || fields.sublocality,
        state: fields.administrative_area_level_1 || '',
        zipCode: fields.postal_code || '',
        country: fields.country || '',
        placeId: place.place_id,
        lat,
        lng
      })

      setError('')
    },
    [addressState]
  )

  return {
    query,
    addressState,
    error,
    handleQueryChange,
    handleAddressChange,
    handleAddressSelect,
    handlePlaceSelect,
    handleAutocompleteError
  }
}
