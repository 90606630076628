import React, { useEffect } from 'react'
import { RouteComponentProps, Router, useParams } from '@reach/router'
import './App.css'
import { Login } from './pages/Login'
import { Dashboard } from './pages/Dashboard'
import { PrivateRoute } from './components/Auth/PrivateRouter'
import { AdminPrivateRoute } from './components/Auth/AdminPrivate'
import { VerifyToken } from './components/Auth/MagicLink'
import { ActivateLink } from './components/Auth/ActivateLink'
import { ApplicationContainer } from './components/Application/ApplicationContainer'
import { Petition } from './pages/Petition'
import { Registration } from './pages/Registration'
import { VoterRegistration } from './pages/Registration/VoterRegistration'
import { Profile } from './pages/Profile'
import { RegisteredConfirmation } from './pages/Petition/RegisteredConfirmation'
import { PetitionConfirmation } from './pages/Petition/PetitionConfirmation'
import { CandidateRegistration } from './pages/Registration/CandidateRegistration'
import { CandidateConfirmation } from './pages/Registration/CandidateConfirmation'
import { ApplicationsContainer } from './pages/Applications'
import { ReportsContainer } from './pages/Reports'
import { UserContainer } from './components/User/UserContainer'
import { EditUser } from './components/User/ui/UserEdit'
import { Petitions } from './components/Petitions'
import { AdminPetitions } from './components/Petitions/AdminPetitions'
import { PetitionSubmissions } from './components/Petitions/ui/PetitionSubmissions'

import { VerifyEmail } from './components/Auth/ConfirmEmail'
import { Logout } from './pages/Login/Logout'
import { Registered } from './pages/Registration/ui/Registered'

import { VoterContainer } from './pages/Voter/index'
import { StationContainer } from './pages/Station/index'

import { BallotContainer } from './pages/Voter/ui/BallotContainer'
import { PrivateRouteVoter } from './components/Auth/PrivateRouterVoter'
import { BallotSuccess } from './pages/Voter/ui/BallotSuccess'
import { BallotSuccess as StationBallotSuccess } from './pages/Station/ui/BallotSuccess'

import { VerifyVoterContainer } from './pages/Voter/VerifyVoterContainer'
import { VoterRegistrationContainer } from './pages/Voter/VoterRegistrationContainer'
import { StationBallotContainer } from './pages/Station/ui/StationBallotContainer'

const StationMap: React.FC<RouteComponentProps> = () => {
  const { token } = useParams()

  useEffect(() => {
    window.localStorage.setItem('station', token)
  }, [])

  return <p>Allocated: token</p>
}

const App: React.FC = () => {
  if (process.env.REACT_APP_SITE_ID === 'STATION') {
    return (
      <>
        <Router>
          <StationContainer path="/" />
          <PrivateRouteVoter component={StationBallotContainer} path="/voter/ballot" redirect="/" />
          <PrivateRouteVoter component={StationBallotSuccess} path="/voter/success" redirect="/" />
          <PrivateRouteVoter component={StationBallotSuccess} path="/station/:token" />
          <Logout path="/logout" />
        </Router>
        <small>v 1.0.0</small>
      </>
    )
  }

  return (
    <Router>
      <VoterContainer path="/" step="form" />

      <Login path="/login" />
      <Login path="/verify" redirect="/voter/verify" />
      <Logout path="/logout" />

      <Registration path="/register" />

      <CandidateRegistration path="/register/candidate" />
      <CandidateConfirmation path="/register/candidate/confirm" />

      <VoterRegistration path="/register/voter" />
      <RegisteredConfirmation path="/register/voter/confirm" />
      <Registered path={'/register/voter/login'} />

      <ActivateLink path="/activate/:id" />
      <VerifyToken path="/verify/:id" />
      <VerifyEmail path="/verifyEmail/:id" />

      <Petition path="/petition/:petitionId" />
      <PetitionConfirmation path="/petition/:petitionId/confirm" />
      <RegisteredConfirmation path="/petition/:petitionId/register" />

      <PrivateRoute component={PetitionSubmissions} path="/petition/:petitionId/submissions/" />

      <PrivateRoute component={Dashboard} path="/dashboard" />
      <PrivateRoute component={Profile} path="/profile" />
      <PrivateRoute component={ApplicationContainer} path="/application/:applicationId" />
      <PrivateRoute component={Petitions} path="/petitions" />
      <AdminPrivateRoute component={ApplicationsContainer} path="/applications" />
      <AdminPrivateRoute component={ApplicationsContainer} path="/applications/:status" />
      <AdminPrivateRoute component={ReportsContainer} path="/reports" />
      <AdminPrivateRoute component={UserContainer} path="/users" />
      <AdminPrivateRoute component={EditUser} path="/users/:id" />
      <AdminPrivateRoute component={AdminPetitions} path="/petitions/admin" />

      <VoterContainer path="/voter/login" step="form" />
      <PrivateRouteVoter component={BallotContainer} path="/voter/ballot" />
      <PrivateRouteVoter component={BallotSuccess} path="/voter/ballot/success" />
      <AdminPrivateRoute roles={['ADMIN', 'ELECTION']} component={VerifyVoterContainer} path="/voter/verify" />
      <AdminPrivateRoute
        roles={['ADMIN', 'ELECTION']}
        component={VoterRegistrationContainer}
        path="/voter/registration"
      />
    </Router>
  )
}

export default App
