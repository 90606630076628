import { Box } from '@plusplusminus/plusplusdash'
import { Layout } from '../../components/Layout'
import { RegistrationVoterForm } from './ui/RegistrationVoterForm'

export const VoterRegistrationContainer: React.FC = () => {
  return (
    <Layout showVoterMenu={true}>
      <Box className="flex flex-col">
        <Box className="p-5 pb-0">
          <h1 className="text-lg">Register voter</h1>
        </Box>
        <Box className="mx-2 p-5">
          <RegistrationVoterForm />
        </Box>
      </Box>
    </Layout>
  )
}
