import { Box, Table, Loader, Alert } from '@plusplusminus/plusplusdash'
import { Link, RouteComponentProps, useParams, WindowLocation } from '@reach/router'
import { useQuery, gql } from '@apollo/client'

import dayjs from 'dayjs'
import { useSort } from '../../hooks/useSort'
import { useEffect } from 'react'
import { usePagination } from '../../hooks/usePagaination'
import { PaginatedEntity } from '../../common/types'
import { ApplicationProps } from '../../components/Application/ ui/ApplicationView'
import { Layout } from '../../components/Layout'
import { ApplicationSearch } from '../../components/Application/ ui/ApplicationSearch'
import { Input } from '../../components/Application/ ui/ApplicationSearch'

const APPLICATIONS_QUERY = gql`
  query UsersQuery($input: QueryApplicationInput!, $pagination: PaginationArgs!, $sort: SortApplicationInput!) {
    searchApplications(input: $input, pagination: $pagination, sort: $sort) {
      docs {
        id
        status
        owner {
          firstName
          lastName
          idnumber
          email
        }

        template {
          id
          name
          requirements {
            name
            role
            desription
            options {
              structure
            }
          }
        }

        requirements {
          value
        }

        candidates {
          status
        }
        createdAt
        updatedAt
      }
      totalDocs
      hasPrevPage
      hasNextPage
    }
  }
`

interface IApplications {
  searchApplications: PaginatedEntity<ApplicationProps>
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'firstName', isSortable: true },
  { label: 'Email', key: 'email', isSortable: true },
  { label: 'Status', key: 'status', isSortable: false },
  { label: 'Application', key: 'application', isSortable: false },
  //{ label: 'Completed', key: 'completed', isSortable: true },
  { label: 'Created Date', key: 'createdAt', isSortable: true },
  { label: 'Last Updated', key: 'updatedAt', isSortable: true }
]

const NavLink = (props: any) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: `${
          isCurrent ? 'text-green' : 'text-gray-500'
        } rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`
      }
    }}
  />
)

export const ApplicationsContainer: React.FC<RouteComponentProps<{ location: WindowLocation<any> }>> = (props) => {
  const { status } = useParams()
  const [page, offset, limit, nextPage, prevPage] = usePagination(50)
  const [sortField, direction, sortCallback] = useSort()

  const { data, loading, error, previousData, refetch } = useQuery<IApplications>(APPLICATIONS_QUERY, {
    variables: {
      sort: { direction: 'DESC', sortField: 'createdAt' },
      pagination: { limit, sort: 'DESC', page },
      input: {
        status: status
      }
    }
  })

  const tableData = data || previousData

  useEffect(() => {
    if (props?.location?.state?.refetch) {
      refetch()
    }
  }, [props.location])

  useEffect(() => {
    if (sortField && direction) {
      refetch({
        sort: { direction: direction, sortField: sortField },
        pagination: { limit, sort: 'DESC', page },
        input: {
          status: status
        }
      })
    }
  }, [sortField, direction])

  const refetchApplications = (input: Input) => {
    if (input) {
      refetch({ input: { status, ...input } })
    } else {
      refetch()
    }
  }

  return (
    <Layout>
      <Box className="flex flex-col">
        <Box className="p-5 pb-0">
          <h1 className="text-lg">Applications</h1>
          <ApplicationSearch refetch={refetchApplications} />
        </Box>
        {error ? (
          <Alert type="error">
            <Alert.Heading>There was an error fetching users</Alert.Heading>
          </Alert>
        ) : !previousData && loading ? (
          <div className="flex flex-auto justify-center items-center">
            <Loader isActive={true} />
          </div>
        ) : tableData ? (
          <Box>
            <Table
              shape={TABLE_SHAPE}
              sortCallback={sortCallback}
              activeSort={direction === 'desc' ? sortField?.slice(1) : sortField}
              sortDirection={direction}
            >
              {tableData.searchApplications.docs.map((application) => {
                return (
                  <Table.Row key={application.id}>
                    <Table.Cell className="font-medium text-gray-900">
                      {application.owner.firstName} {application.owner.lastName}
                    </Table.Cell>
                    <Table.Cell>{application.owner.idnumber}</Table.Cell>

                    <Table.Cell>{application.status}</Table.Cell>
                    <Table.Cell>{application.template.name}</Table.Cell>
                    <Table.Cell>
                      {application.createdAt ? dayjs(application.createdAt).format('DD-MM-YYYY') : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {application.updatedAt ? dayjs(application.updatedAt).format('DD-MM-YYYY') : ''}
                    </Table.Cell>

                    <Table.Cell>
                      <a className="underline" target={'_blank'} href={`/application/${application.id}`}>
                        View
                      </a>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table>
            <div className="flex justify-end py-4 px-8">
              <div className="w-16">
                {tableData.searchApplications.hasPrevPage && (
                  <a className="underline text-md cursor-pointer" onClick={() => prevPage()}>
                    Prev
                  </a>
                )}
              </div>
              <span className="w-16 text-md mx-4">Page {page}</span>
              <div className="w-16">
                {tableData.searchApplications.hasNextPage && (
                  <a className="underline text-md cursor-pointer" onClick={() => nextPage()}>
                    Next
                  </a>
                )}
              </div>
            </div>
          </Box>
        ) : null}
      </Box>
    </Layout>
  )
}
