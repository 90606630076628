import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { Alert } from '../../Alert'

interface RegisterButtonProps {
  applicationId: string
  refetch: () => void
}

export const ApplicationNote: React.FC<RegisterButtonProps> = ({ applicationId, refetch }) => {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const { register, handleSubmit, reset } = useForm()

  const [addNote, { loading }] = useMutation(ADD_NOTE, {
    onCompleted: (response) => {
      if (response?.createNote?.id) {
        refetch()
        reset()
        setError(false)
        setSuccess(true)
      }
    },
    onError: (error) => {
      if (error) {
        alert(error.message)
        setSuccess(false)
        setError(true)
      }
    }
  })

  const onSubmit = (values: { comment: string }) => {
    setSuccess(false)
    setError(false)
    addNote({
      variables: {
        createNoteInput: {
          comment: values.comment,
          applicationId: applicationId
        }
      }
    })
  }

  return (
    <div className="bg-gray-50 px-4 py-6 sm:px-6 ">
      <div className="flex space-x-3">
        <div className="min-w-0 flex-1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="comment" className="sr-only">
                About
              </label>
              <Input
                width="full"
                variant="standard"
                as="textarea"
                id="comment"
                name="comment"
                placeholder="Add a note"
                ref={register({ required: true })}
              ></Input>
            </div>
            <div className="mt-3 flex items-center justify-between">
              <div />
              <button
                type="submit"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {loading ? 'Loading...' : 'Comment'}
              </button>
            </div>
          </form>
        </div>
      </div>
      {success && <Alert color="green" title="Success" message="Note added successfully." />}
      {error && <Alert color="red" title="Error" message="Something went wrong please try again later." />}
    </div>
  )
}

const ADD_NOTE = gql`
  mutation addNote($createNoteInput: CreateNoteInput!) {
    createNote(createNoteInput: $createNoteInput) {
      id
    }
  }
`
