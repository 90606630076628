interface ListLeftRightProps {
  title: string
  content: string | React.ReactNode
  description?: string
}
export const ListLeftRight: React.FC<ListLeftRightProps> = ({ title, content, description }) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">
        {title}
        {description ? <p className="my-4 text-light text-gray-500">{description}</p> : null}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{content}</dd>
    </div>
  )
}
