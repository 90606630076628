import React from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'
import { Loader } from '@plusplusminus/plusplusdash'

interface IPrivateRoute extends RouteComponentProps {
  component: React.ElementType
  redirect?: string
}

const PrivateRouteVoter: React.FC<IPrivateRoute> = ({ component: Component, redirect = '/voter/login', ...props }) => {
  const { loading, data, error } = useUserQuery()

  if (loading) return <Loader />

  if (error) return <Redirect noThrow to={redirect} />

  return data.me ? <Component {...props} /> : <Redirect noThrow to={redirect} />
}

export { PrivateRouteVoter }
