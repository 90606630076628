import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Layout } from '../Layout'
import { HeaderWithActions } from '../../components/Header/HeaderWithActions'
import {PetitionList} from './ui/PetitionList'

export const Petitions: React.FC = () => {
  const { data, loading, refetch } = useQuery(PETITIONS_QUERY)

  if (loading) return null

  return (
    <Layout>
      <HeaderWithActions title="Petitions" actions={[]} />
      <PetitionList items={data.petitions}/>
    </Layout>
  )
}

const PETITIONS_QUERY = gql`
  query getPetitions {
    petitions{
      id
      submissions{
        id
      }
      isArchived
      application{
        template{
        name
      }
      }
      location{
        name
        municipality{
          name
        }
      }
    }
  }
`
