import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useParams } from '@reach/router'
import { ApplicationView } from './ ui/ApplicationView'

export const ApplicationContainer: React.FC = () => {
  const { applicationId } = useParams()
  const { data, loading, refetch } = useQuery(APPLICATION_QUERY, {
    variables: {
      id: applicationId
    }
  })

  if (loading) return null

  return <ApplicationView application={data.application} refetch={refetch} />
}

const APPLICATION_QUERY = gql`
  query getApplications($id: String!) {
    application(id: $id) {
      id
      isArchived
      owner {
        firstName
        lastName
        email
        cellphone
        idnumber
      }
      status
      files {
        key
        isApproved
        meta {
          requirementId
        }
      }
      notes {
        comment
        createdAt
      }
      requirements {
        id: requirementId
        value
      }
      template {
        id
        name
        requirements {
          id
          name
          type: role
          isAdmin
          desription
          options {
            structure
            template
          }
        }
      }
      candidates {
        id
        isArchived
        petition {
          id
          submissionCount
        }
        location {
          name
          code
        }
      }
    }
  }
`
