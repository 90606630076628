import { gql, useMutation } from '@apollo/client'
import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import React, { useState } from 'react'
import { useUserQuery } from '../../../hooks/useUserQuery'
import { uploadApplicationFile } from '../../../services/files'
import { HeaderWithActions } from '../../Header/HeaderWithActions'
import { Layout } from '../../Layout'
import { ListLeftRight } from '../../List/ListLeftRight'
import { StructuresContainer } from '../../Structures/StructuresContainer'
import { Candidate } from '../../Structures/useStructuresHook'
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { WithdrawApplication } from './ApplicationWithdraw'
import { DownloadFile } from './DownloadFile'
import { ApplicationNote } from './ApplicationNote'

interface ApplicationFileMeta {
  requirementId: string
}

interface ApplicationFiles {
  key: string
  isApproved: boolean
  meta: ApplicationFileMeta
}

interface RequirementOption {
  structure?: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  template?: string
}

interface Requirements {
  id: string
  name: string
  type: string
  isAdmin: boolean
  desription: string
  value?: string
  options?: RequirementOption
}
interface Template {
  id: string
  name: string

  requirements: Array<Requirements>
}

interface User {
  firstName: string
  lastName: string
  email: string
  cellphone: string
  idnumber: string
}

interface ApplicationNote {
  comment: string
  createdAt: Date
}
interface RequirementMeta {
  value: string
  id: string
}
export interface ApplicationProps {
  id: string
  owner: User
  isArchived: boolean
  template: Template
  requirements: Array<RequirementMeta>
  files: Array<ApplicationFiles>
  notes: Array<ApplicationNote>
  candidates: Array<Candidate>
  status: 'pending' | 'review' | 'withdrawn'
  createdAt: string
  updatedAt: string
  requirementsCompleted: number
}

interface Props {
  application: ApplicationProps
  refetch: () => void
}

export const ApplicationView: React.FC<Props> = ({ application, refetch }) => {
  const { loading, data } = useUserQuery()
  const [fileLoad, setFileLoad] = useState('')

  const isAdmin = data.me.role === 'ADMIN'
  const [submit] = useMutation(SUBMIT_APPLICATION, {
    variables: {
      applicationId: application.id
    }
  })

  const [
    changeStatus,
    { loading: changeStatusLoading, data: changeStatusResponse, error: changeStatusErr }
  ] = useMutation(APPROVE_APPLICATION)

  const [addRequirement] = useMutation(ADD_REQUIREMENT, {})
  const [removeFile] = useMutation(REMOVE_REQUIREMENT, {})

  const onChangeRequirement = async (id: string, value: string) => {
    await addRequirement({
      variables: {
        input: {
          requirementId: id,
          value,
          applicationId: application.id
        }
      }
    })
    await refetch()
  }

  const onRemoveFile = async (id: string) => {
    await removeFile({
      variables: {
        requirementId: id,
        applicationId: application.id
      }
    })
    await refetch()
  }

  if (loading) return <div>Loading</div>

  // eslint-disable-next-line prefer-const
  let declaration = application.template.requirements.find((req) => {
    return req.type === 'declaration'
  })

  const checkValue = (id: string) => {
    const result = application.requirements.find((req) => {
      return req.id === id
    })

    if (!result) {
      return false
    }

    if (result) {
      return result.value === 'checked'
    }

    return false
  }

  const actions: React.ReactNode[] = []

  if (data.me.role === 'USER') {
    if (application.status === 'pending' && !application.isArchived) {
      actions.push(
        <Button type="button" variant="primary" onClick={() => submit()}>
          Submit
        </Button>
      )
    }
  } else if (data.me.role === 'ADMIN') {
    //if (application.status === 'review') {
    if (application.status !== 'withdrawn') {
      actions.push(
        <>
          <Button
            disabled={changeStatusLoading}
            type="button"
            variant="primary"
            colorScheme="green"
            onClick={() =>
              changeStatus({
                variables: {
                  applicationId: application.id,
                  status: 'approved'
                }
              })
            }
          >
            {changeStatusLoading ? 'Loading' : 'Approve'}
          </Button>
          <Button
            disabled={changeStatusLoading}
            type="button"
            variant="primary"
            colorScheme="red"
            className="ml-2"
            onClick={() =>
              changeStatus({
                variables: {
                  applicationId: application.id,
                  status: 'rejected'
                }
              })
            }
          >
            {changeStatusLoading ? 'Loading' : 'Reject'}
          </Button>
        </>
      )
    }

    //}
  }

  if (application.isArchived) {
    return (
      <Layout>
        <HeaderWithActions title={'Applications'} actions={actions} />
        <div className="bg-white  overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Application Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <ListLeftRight
                content={`${application.owner.firstName} ${application.owner.lastName}`}
                title={'Full Name'}
              />
              <ListLeftRight content={application.owner.idnumber} title={'ID Number'} />
              <ListLeftRight content={application.template.name} title={'Application'} />
              <ListLeftRight content={application.status} title={'Status'} />
              <ListLeftRight content={application.owner.email} title={'Email'} />
              <ListLeftRight content={application.owner.cellphone} title={'Cellphone'} />
              <ListLeftRight
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {application.template.requirements
                      .filter((req) => {
                        return req.type === 'file'
                      })
                      .map((req) => {
                        const file = application.files.find((file) => file.meta.requirementId === req.id)

                        if (file) {
                          return (
                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                              <div className="w-0 flex-1 flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="flex-shrink-0 h-5 w-5 text-green-400"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <span className="ml-2 flex-1 w-0 truncate">
                                  {req.name}
                                  <p className="mt-1 max-w-2xl text-sm text-gray-500">{req.desription}</p>
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <DownloadFile applicationId={application.id} file={file.key} />
                              </div>
                            </li>
                          )
                        }
                      })}
                  </ul>
                }
                title={'Documents and Files'}
              />
              {declaration ? (
                <ListLeftRight
                  content={
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <span className="ml-2 flex-1 w-0 ">
                            <p className="mb-4 max-w-2xl text-sm text-gray-900">
                              I, {application.owner.firstName} {application.owner.lastName} with ID Number{' '}
                              {application.owner.idnumber} , do hereby declare that:
                            </p>
                            <ul className="list-disc ml-4">
                              <li>I am a South African citizen or permanent resident with a valid ID document.</li>
                              <li>
                                I fully ascribe to the values and vision of ActionSA and am committed to abide by its
                                Constitution.
                              </li>
                              <li>
                                I am not in arrears with my rates and tariffs owing to the municipality in which I
                                reside and (or) I am applying to be a candidate in.
                              </li>
                              <li>
                                I am have not been convicted of a criminal offence and sentenced to more than 12 months
                                imprisonment without the option of a fine either in the Republic, or outside of the
                                Republic if the conduct constituting the offence would have been an offence in the
                                Republic and a period of 5 years has not elapsed since the sentence was completed.
                              </li>
                              <li>
                                I am not aware of anything, either current or past, that would foreseeably bring the
                                name of ActionSA into disrepute.
                              </li>
                              <li>I have not been declared as an unrehabilitated insolvent.</li>
                              <li>I have not been deemed to be of unsound mind by a court of the Republic.</li>
                              <li>
                                I am not in the employ of a company, organisation or tier of government that would
                                prohibit my employment as a Councillor.
                              </li>
                              <li>I abide by the provisions contained in the Candidate Selection Nomination Policy.</li>
                              <li>
                                I have read and understood the candidate’s responsibility towards payment of candidate
                                fees and Public Representative contribution.
                              </li>
                            </ul>
                            <div className="mt-3">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  disabled
                                  className="form-radio h-4 w-4"
                                  name="radio-sizes"
                                  checked={checkValue(declaration.id)}
                                />
                                <span className="ml-2">{declaration.desription}</span>
                              </label>
                            </div>
                          </span>
                        </div>
                      </li>
                    </ul>
                  }
                  title={declaration.name}
                />
              ) : null}
              {application.candidates && application.candidates.length ? (
                <ListLeftRight
                  description="You will need to secure a minimum of 500 endorsements from each ward in which you wish to stand"
                  content={
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {application.candidates
                        .filter((p) => !p.isArchived)
                        .map((candidate) => {
                          return (
                            <li className="pl-3 pr-4 py-3 text-sm">
                              <div className="flex items-center justify-between">
                                <div className="w-0 flex-1 flex items-center">
                                  <span className="ml-2 flex-1 w-0 truncate">
                                    <span className=" flex-1 w-0 truncate">
                                      {candidate.location.name}
                                      <p className="mt-1 max-w-2xl text-sm text-gray-500">{candidate.location.code}</p>
                                    </span>
                                  </span>

                                  <span className="ml-2 flex-1 w-0 truncate">
                                    <span className=" flex-1 w-0 truncate">
                                      Submission Count
                                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        {candidate.petition.submissionCount}
                                      </p>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  }
                  title={'Petitions'}
                />
              ) : null}
            </dl>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <HeaderWithActions title={'Applications'} actions={actions} />
      <div className="bg-white  overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Application Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <ListLeftRight
              content={`${application.owner.firstName} ${application.owner.lastName}`}
              title={'Full Name'}
            />
            <ListLeftRight content={application.owner.idnumber} title={'ID Number'} />
            <ListLeftRight content={application.template.name} title={'Application'} />
            <ListLeftRight content={application.status} title={'Status'} />
            <ListLeftRight content={application.owner.email} title={'Email'} />
            <ListLeftRight content={application.owner.cellphone} title={'Cellphone'} />

            {application.template.requirements
              .filter((req) => {
                return req.type === 'location'
              })
              .map((req) => {
                return (
                  <ListLeftRight
                    title={req.name}
                    content={
                      <Box>
                        <Box className="mb-4 border-bottom-1">
                          <StructuresContainer
                            structure={req.options?.structure ?? 'WARD'}
                            applicationId={application.id}
                            locations={application.candidates.filter((p) => !p.isArchived)}
                            refetch={refetch}
                          />
                        </Box>
                      </Box>
                    }
                  />
                )
              })}
            <ListLeftRight
              content={
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {application.template.requirements
                    .filter((req) => {
                      return req.type === 'file'
                    })
                    .map((req) => {
                      const file = application.files.find((file) => file.meta.requirementId === req.id)

                      if (file) {
                        return (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="flex-shrink-0 h-5 w-5 text-green-400"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <span className="ml-2 flex-1 w-0 truncate">
                                {req.name}
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">{req.desription}</p>
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <DownloadFile applicationId={application.id} file={file.key} />
                              <Button
                                variant="secondary"
                                colorScheme="red"
                                className="ml-2"
                                onClick={() => onRemoveFile(req.id)}
                              >
                                Remove File
                              </Button>
                            </div>
                          </li>
                        )
                      }

                      return (
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <svg
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            <span className="ml-2 flex-1 w-0 truncate">
                              {req.name}
                              <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-2">{req.desription}</p>
                              {req.options?.template ? (
                                <a
                                  href={req.options.template}
                                  target="_download"
                                  className="mt-4 font-medium text-green hover:text-green-500"
                                >
                                  Download Template
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <label
                              htmlFor={`${req.id}-file-upload`}
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>{fileLoad === req.id ? 'Uploading...' : 'Upload file'}</span>
                              <Input
                                as="input"
                                variant="ghost"
                                width="full"
                                id={`${req.id}-file-upload`}
                                name={`${req.id}-file-upload`}
                                type="file"
                                className="sr-only"
                                accept="application/msword, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={async (e) => {
                                  setFileLoad(req.id)
                                  const { files = [] } = e.target as HTMLInputElement
                                  if (files?.length) {
                                    await uploadApplicationFile(application.id, req.id, files[0])
                                    refetch()
                                    setFileLoad('')
                                  }
                                }}
                              />
                            </label>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              }
              title={'Documents and Files'}
            />

            {declaration ? (
              <ListLeftRight
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <span className="ml-2 flex-1 w-0 ">
                          <p className="mb-4 max-w-2xl text-sm text-gray-900">
                            I, {application.owner.firstName} {application.owner.lastName} with ID Number{' '}
                            {application.owner.idnumber} , do hereby declare that:
                          </p>
                          <ul className="list-disc ml-4">
                            <li>I am a South African citizen or permanent resident with a valid ID document.</li>
                            <li>
                              I fully ascribe to the values and vision of ActionSA and am committed to abide by its
                              Constitution.
                            </li>
                            <li>
                              I am not in arrears with my rates and tariffs owing to the municipality in which I reside
                              and (or) I am applying to be a candidate in.
                            </li>
                            <li>
                              I am have not been convicted of a criminal offence and sentenced to more than 12 months
                              imprisonment without the option of a fine either in the Republic, or outside of the
                              Republic if the conduct constituting the offence would have been an offence in the
                              Republic and a period of 5 years has not elapsed since the sentence was completed.
                            </li>
                            <li>
                              I am not aware of anything, either current or past, that would foreseeably bring the name
                              of ActionSA into disrepute.
                            </li>
                            <li>I have not been declared as an unrehabilitated insolvent.</li>
                            <li>I have not been deemed to be of unsound mind by a court of the Republic.</li>
                            <li>
                              I am not in the employ of a company, organisation or tier of government that would
                              prohibit my employment as a Councillor.
                            </li>
                            <li>I abide by the provisions contained in the Candidate Selection Nomination Policy.</li>
                            <li>
                              I have read and understood the candidate’s responsibility towards payment of candidate
                              fees and Public Representative contribution.
                            </li>
                          </ul>
                          <div className="mt-3">
                            <label className="inline-flex items-center">
                              <input
                                type="checkbox"
                                className="form-radio h-4 w-4"
                                name="radio-sizes"
                                onChange={async (e) => {
                                  if (declaration?.id) {
                                    await onChangeRequirement(declaration?.id, e.target.checked ? 'checked' : '')
                                  }
                                }}
                                checked={checkValue(declaration.id)}
                              />
                              <span className="ml-2">{declaration.desription}</span>
                            </label>
                          </div>
                        </span>
                      </div>
                    </li>
                  </ul>
                }
                title={declaration.name}
              />
            ) : null}

            {isAdmin ? (
              <ListLeftRight
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {application.template.requirements
                      .filter((req) => {
                        return req.isAdmin
                      })
                      .map((req) => {
                        const meta = application.requirements.find((requirement) => requirement.id == req.id)

                        return (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <span className="ml-2 flex-1 w-0 truncate">
                                {req.name}
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">{req.desription}</p>
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <div className="mt-1">
                                <label className="inline-flex items-center">
                                  <input
                                    type="checkbox"
                                    className="form-radio h-4 w-4"
                                    name="radio-sizes"
                                    onChange={async (e) => {
                                      await onChangeRequirement(req.id, e.target.checked ? 'checked' : '')
                                    }}
                                    checked={meta ? meta.value === 'checked' : false}
                                  />
                                </label>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                }
                title={'Admin Actions'}
              />
            ) : null}

            {application.candidates && application.candidates.length ? (
              <ListLeftRight
                description="You will need to secure a minimum of 500 endorsements from each ward in which you wish to stand"
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {application.candidates
                      .filter((p) => !p.isArchived)
                      .map((candidate) => {
                        return (
                          <li className="pl-3 pr-4 py-3 text-sm">
                            <div className="flex items-center justify-between">
                              <div className="w-0 flex-1 flex items-center">
                                <span className="ml-2 flex-1 w-0 truncate">
                                  <span className=" flex-1 w-0 truncate">
                                    {candidate.location.name}
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{candidate.location.code}</p>
                                  </span>
                                </span>

                                <span className="ml-2 flex-1 w-0 truncate">
                                  <span className=" flex-1 w-0 truncate">
                                    Submission Count
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                      {candidate.petition.submissionCount}
                                    </p>
                                  </span>
                                </span>

                                <EmailShareButton
                                  url={`https://vote.actionsa.org.za/petition/${candidate.petition.id}`}
                                  subject={`${application.owner.firstName} is standing in ActionSA's candidate elections, and needs your help.`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    />
                                  </svg>
                                </EmailShareButton>
                                <TwitterShareButton
                                  title={`${application.owner.firstName} is standing in ActionSA's candidate elections, and needs your help.`}
                                  url={`https://vote.actionsa.org.za/petition/${candidate.petition.id}`}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-2"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </TwitterShareButton>
                                <FacebookShareButton
                                  url={`https://vote.actionsa.org.za/petition/${candidate.petition.id}`}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-2"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </FacebookShareButton>
                                <WhatsappShareButton
                                  title={`${application.owner.firstName} is standing in ActionSA's candidate elections, and needs your help.`}
                                  url={`https://vote.actionsa.org.za/petition/${candidate.petition.id}`}
                                >
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-2"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </WhatsappShareButton>
                              </div>

                              <div className="ml-4 flex-shrink-0">
                                <a
                                  href={`/petition/${candidate.petition.id}`}
                                  target={'_blank'}
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                  View Petition
                                </a>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                }
                title={'Petitions'}
              />
            ) : null}
            <WithdrawApplication
              applicationId={application.id}
              changeStatus={changeStatus}
              changeStatusLoading={changeStatusLoading}
              status={application.status}
              response={changeStatusResponse}
              error={changeStatusErr}
            />
          </dl>
        </div>
        <section aria-labelledby="notes-title">
          <div className="bg-white border-t border-gray-200 sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                  Notes
                </h2>
              </div>

              <div className="px-4 py-6 sm:px-6">
                <ul className="space-y-8">
                  {application.notes.map((note) => {
                    return (
                      <li>
                        <div className="flex space-x-3">
                          <div>
                            <div className="text-sm">
                              <span className="font-medium text-gray-900">Note</span>
                            </div>
                            <div className="mt-1 text-sm text-gray-700">
                              <p>{note.comment}</p>
                            </div>
                            <div className="mt-2 text-sm space-x-2">
                              <span className="text-gray-500 font-medium">{note.createdAt}</span>
                              <span className="text-gray-500 font-medium">&middot;</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                  {!application.notes.length ? (
                    <li>
                      <div className="flex space-x-3">
                        <div>
                          <div className="text-sm">
                            <span className="font-medium text-gray-900">No notes added.</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <ApplicationNote applicationId={application.id} refetch={refetch} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

const SUBMIT_APPLICATION = gql`
  mutation submitApplication($applicationId: String!) {
    submitApplication(applicationId: $applicationId) {
      id
      status
    }
  }
`

const APPROVE_APPLICATION = gql`
  mutation changeStatus($applicationId: String!, $status: String!) {
    changeStatus(applicationId: $applicationId, status: $status) {
      id
      status
    }
  }
`

const ADD_REQUIREMENT = gql`
  mutation submitApplication($input: CreateApplicationRequirementInput!) {
    addApplicationRequirement(input: $input)
  }
`

const REMOVE_REQUIREMENT = gql`
  mutation removeFile($applicationId: String!, $requirementId: String!) {
    removeFile(applicationId: $applicationId, requirementId: $requirementId)
  }
`
