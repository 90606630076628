import { useMutation } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import gql from 'graphql-tag'
import { useState } from 'react'
import { LayoutBox } from '../../components/LayoutBox'
import { useOtpVerify } from '../../hooks/useRegistration'
import { InputValidator } from '../../services/validate'
import { OtpVerification } from './ui/OtpVerification'
import { RegistrationForm } from './ui/RegistrationForm'

interface RegisterFormData {
  firstName: string
  lastName: string
  cellphone: string
  email: string
  idnumber: string
  pdcandidate: boolean
  candidate: boolean
  mayor: boolean
}

export const CandidateRegistration: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState('form')
  const [error, setError] = useState({ message: '', action: '' })
  const [user, setUser] = useState({ id: '', token: '' })
  const form = useOtpVerify({
    activate: true,
    onSuccess: () => {
      navigate('/register/candidate/confirm')
    },
    onError: (err: string) => {
      console.log(err)
    }
  })

  const [register] = useMutation(REGISTER_USER)

  const onRegister = (data: RegisterFormData) => {
    const { pdcandidate, candidate, mayor, ...rest } = data

    const roles: string[] = []
    setError({ message: '', action: '' })
    if (candidate) {
      roles.push('candidate')
    }
    if (pdcandidate) {
      roles.push('pr')
    }

    if (mayor) {
      roles.push('mayor')
    }

    if (!roles.length) {
      setError({ message: 'Please select a candidacy option', action: '' })
      return
    }

    register({
      variables: {
        input: {
          ...rest,
          cellphone: new InputValidator(data.cellphone).formatCellphone('E.164'),
          meta: {
            templateRoles: roles
          }
        }
      }
    })
      .then(({ data }) => {
        setStep('otp')
        setUser(data.register)
      })
      .catch((err) => {
        if (err.message === 'User already exists.') {
          setError({ message: 'Account already registered', action: 'login' })
        } else {
          setError({
            message: 'Unexpected error has occured please contact vote@actionsa.org.za for assistance.',
            action: ''
          })
        }
      })
  }

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'form':
        return <RegistrationForm onSubmit={onRegister} />

      case 'otp':
        return <OtpVerification form={form} token={user.token} />
    }
  }

  return (
    <LayoutBox
      title="Register as a candidate in the upcoming ActionSA Candidate Elections."
      formTitle="Candidate Registration"
      showLogo={true}
    >
      {getWizardComponent(step)}
      <div className="mt-8">
        <dt className="text-sm font-medium text-gray-900">Need assistance?</dt>
        <dd className="text-sm text-gray-500">
          Email <a href="mailto:vote@actionsa.org.za">vote@actionsa.org.za</a>.
        </dd>
      </div>
    </LayoutBox>
  )
}

const REGISTER_USER = gql`
  mutation registerUser($input: CreateAuthInput!) {
    register: registerWithCellphoneVerification(input: $input) {
      id
      token: otpFingerprint
    }
  }
`
