import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import Autocomplete from 'react-google-autocomplete'
import { useForm } from 'react-hook-form'
import { useAddressSearch } from '../../../hooks/useAddressSearch'
import { InputValidator } from '../../../services/validate'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: true
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    label: 'Cellphone',
    name: 'cellphone',
    placeholder: 'Cellphone',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateCellphone()
        }
      }
    },
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      validate: {
        manual: (value: string) => {
          if (value) {
            return new InputValidator(value).validateEmail()
          }
        }
      }
    },
    type: 'email'
  },
  {
    label: 'ID Number',
    name: 'idnumber',
    placeholder: 'ID Number',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateIdNumber()
        }
      }
    },
    type: 'text'
  }
]

interface Props {
  onSubmit?: (data: any) => void
  loading: boolean
}

export const VoterRegistrationForm: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors } = useForm({})
  const { addressState, handlePlaceSelect } = useAddressSearch({})
  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit({ ...data, address: addressState })
    }
  }

  return (
    <form
      autoComplete="none"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map((field) => {
        return (
          <Box>
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
              {field.label}
            </label>
            <Input
              as="input"
              variant="standard"
              width="full"
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              ref={register(field.options)}
              className="mb-2 w-full"
            />
            {errors[field.name] && errors[field.name].type === 'required' ? (
              <div className="text-sm text-red-300">Required Field</div>
            ) : null}
            {errors[field.name] && errors[field.name].type === 'manual' ? (
              <div className="text-sm text-red-300">Invalid Field</div>
            ) : null}
          </Box>
        )
      })}
      <Box>
        <label htmlFor={'location'} className="block text-sm font-medium text-gray-900 mb-1">
          Address
        </label>
        <Autocomplete
          className="p-2 rounded border-2 border-gray-200 mb-2 w-full w-full"
          apiKey="AIzaSyAbSui3xvIK1mhbFzgrDb408aqVIQN4xxs"
          onPlaceSelected={handlePlaceSelect}
          types={['address']}
          componentRestrictions={{ country: 'za' }}
          placeholder="Enter A Valid Address"
        />
      </Box>
      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <Button size="xl" variant="primary" type="submit" colorScheme="green" disabled={props.loading}>
          {props.loading ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}
