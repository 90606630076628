import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useOtpLogin = ({ onSuccess, onError }: any) => {
  const form = useForm({
    defaultValues: {
      otp: '',
      token: ''
    }
  })

  // auth mutation here
  const [verify, { loading }] = useMutation(LOGIN_OTP)

  const onSubmit = useCallback(
    async (data) => {
      try {
        const response = await verify({ variables: { ...data } })
        onSuccess(response)
      } catch (err) {
        form.setError('otp', { message: err.message })
        onError()
      }
    },
    [verify, onSuccess, onError, form]
  )

  return {
    ...form,
    onSubmit,
    submitting: loading
  }
}

const LOGIN_OTP = gql`
  mutation loginOtp($otp: String!, $token: String!) {
    loginByOtp(otp: $otp, id: $token) {
      message
    }
  }
`
