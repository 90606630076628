import React, { useEffect, useState } from 'react'
import { Input, Button, Box, Grid, Form } from '@plusplusminus/plusplusdash'
import { StructureComponent } from '../../Structures/ui/StructureComponent'

interface Props {
  refetch: (value: Input) => void
}
export interface Input {
  status?: string | null
  firstName?: string
  lastName?: string
  idnumber?: string
  locationId?: string
  muncipalityId?: string
  provinceId?: string
  wardId?: string
  applicationType?: string
}

type StructureComponentProps = {
  structure: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  filter: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  level: number
  search: string
  resets?: Array<'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'>
}

const components: Array<StructureComponentProps> = [
  {
    structure: 'PROVINCE',
    search: 'provinceId',
    level: 6,
    filter: 'COUNTRY',
    resets: ['MUNICIPALITY', 'WARD']
  },
  {
    structure: 'MUNICIPALITY',
    level: 5,
    search: 'muncipalityId',
    filter: 'PROVINCE',
    resets: ['WARD']
  },
  {
    structure: 'WARD',
    search: 'wardId',
    level: 4,
    filter: 'MUNICIPALITY',
    resets: []
  }
]

type StructureForm = {
  COUNTRY: string
  PROVINCE: string
  MUNICIPALITY: string
  WARD: string
}

export const ApplicationSearch: React.FC<Props> = ({ refetch }) => {
  const [input, setInput] = useState<Input>({})
  const [form, setForm] = useState({
    COUNTRY: 'ZA',
    WARD: '',
    MUNICIPALITY: '',
    PROVINCE: 'GP'
  })

  useEffect(() => {
    refetch({ ...input })
  }, [input.status])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const queryVars = {
      ...input
    }
    refetch(queryVars)
  }

  const handleInputChange = (event: any) => {
    const target = event.target
    const name = target.name
    const value = target.value
    setInput({ ...input, [name]: value })
  }

  return (
    <>
      <Box className="mt-2">
        <Form className="flex flex-col mb-2" onSubmit={handleSubmit}>
          <Box className="mb-2">
            <Grid columns={4} rows={1} gap={2} className="mb-2">
              <Box className="lex flex-wrap content-start">
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  FIRST NAME
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  onChange={handleInputChange}
                  value={input?.firstName || ''}
                />
              </Box>
              <Box>
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  LAST NAME
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  onChange={handleInputChange}
                  value={input?.lastName || ''}
                />
              </Box>
              <Box>
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  ID NUMBER
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="idnumber"
                  placeholder="ID Number"
                  type="text"
                  onChange={handleInputChange}
                  value={input?.idnumber || ''}
                />
              </Box>
              <Box>
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  Application Type
                </label>
                <select
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="applicationType"
                  onChange={handleInputChange}
                  value={input?.applicationType || ''}
                >
                  <option value="" />
                  <option value="candidate">Ward Candidates</option>
                  <option value={'pd'}>PR Candidates</option>
                  <option value="voter">Voters</option>
                  <option value="mayor">Mayoral</option>
                </select>
              </Box>
            </Grid>
          </Box>
          <Box className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mb-4">
            {components.map((component) => {
              return (
                <Box className="sm:col-span-2 mb-2">
                  <StructureComponent
                    shouldFilter={false}
                    label={component.structure}
                    structure={component.structure}
                    type={component.filter}
                    code={form[component.filter]}
                    value={form[component.structure]}
                    onStructure={(id, locations) => {
                      const location = locations.find((l: any) => l.code === id)
                      if (location) {
                        setInput({ ...input, [component.search]: location.id })
                      } else {
                        setInput({ ...input, [component.search]: null })
                      }
                      setForm({
                        ...form,
                        [component.structure]: id,
                        ...component.resets?.reduce((result, item) => {
                          result[item] = ''
                          return result
                        }, {} as StructureForm)
                      })
                    }}
                  />
                </Box>
              )
            })}
          </Box>
          <Button variant="primary" className="w-40 mb-2" colorScheme="green" type="submit">
            Search
          </Button>
        </Form>
        <nav className="hidden lg:py-2 lg:flex lg:space-x-8">
          <button
            onClick={() => setInput({ ...input, status: null })}
            className={`${
              input.status === '' ? 'bg-green text-white' : 'text-gray-900 bg-white'
            }  rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`}
            aria-current="page"
          >
            All
          </button>
          <button
            onClick={() => setInput({ ...input, status: 'pending' })}
            className={`${
              input.status === 'pending' ? 'bg-green text-white' : 'text-gray-900 bg-white'
            } rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`}
            aria-current="page"
          >
            Pending
          </button>

          <button
            onClick={() => setInput({ ...input, status: 'review' })}
            className={`${
              input.status === 'review' ? 'bg-green text-white' : 'text-gray-900 bg-white'
            } hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`}
          >
            Review
          </button>

          <button
            onClick={() => setInput({ ...input, status: 'approved' })}
            className={`${
              input.status === 'approved' ? 'bg-green text-white' : 'text-gray-900 bg-white'
            } hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`}
          >
            Approved
          </button>

          <button
            onClick={() => setInput({ ...input, status: 'rejected' })}
            className={`${
              input.status === 'rejected' ? 'bg-green text-white' : 'text-gray-900 bg-white'
            } hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`}
          >
            Rejected
          </button>
          <button
            onClick={() => setInput({ ...input, status: 'withdrawn' })}
            className={`${
              input.status === 'withdrawn' ? 'bg-green text-white' : 'text-gray-900 bg-white'
            } hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium`}
          >
            Withdrawn
          </button>
        </nav>
      </Box>
    </>
  )
}
