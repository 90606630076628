import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useSubmissionVerify = ({ onSuccess, onError, activate = false }: any) => {
  const form = useForm({
    defaultValues: {
      otp: '',
      token: ''
    }
  })

  // auth mutation here
  const [verify, { loading }] = useMutation(VERIFY_SUBMISSION)

  const onSubmit = useCallback(
    async (data) => {
      try {
        const response = await verify({ variables: { ...data, activate } })
        onSuccess(response)
      } catch (err) {
        form.setError('otp', { message: err.message })
        onError()
      }
    },
    [verify, onSuccess, onError, form]
  )

  return {
    ...form,
    onSubmit,
    submitting: loading
  }
}

const VERIFY_SUBMISSION = gql`
  mutation verifySubmission($otp: String!, $token: String!) {
    verifySubmission(otp: $otp, token: $token) {
      message
    }
  }
`
