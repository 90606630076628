import { navigate, RouteComponentProps } from '@reach/router'
import { Button, Loader } from '@plusplusminus/plusplusdash'
import { LayoutBox } from '../../../components/LayoutBox'
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { gql, useMutation } from '@apollo/client'

const LOGOUT = gql`
  mutation logout {
    logout
  }
`

export const BallotSuccess: React.FC<RouteComponentProps> = () => {
  const [logout, { loading }] = useMutation(LOGOUT, {
    onCompleted: () => {
      navigate('/')
    }
  })
  return (
    <LayoutBox
      title={`Success!`}
      description={'Your vote has been successfully submitted.'}
      formTitle="Share with friends and family."
      showLogo={true}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <EmailShareButton
            url={`https://vote.actionsa.org.za/`}
            subject={`I voted in South Africa's first-ever Candidate Elections. Choose your ward and mayoral candidate here:`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </EmailShareButton>
          <TwitterShareButton
            title={`I voted in South Africa's first-ever Candidate Elections. Choose your ward and mayoral candidate here:`}
            url={`https://vote.actionsa.org.za`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              aria-hidden="true"
            >
              <path
                d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                fill="currentColor"
              />
            </svg>
          </TwitterShareButton>
          <FacebookShareButton url={`https://vote.actionsa.org.za`}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              aria-hidden="true"
            >
              <path
                d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                fill="currentColor"
              />
            </svg>
          </FacebookShareButton>
          <WhatsappShareButton
            title={`I voted in South Africa's first-ever Candidate Elections. Choose your ward and mayoral candidate here:`}
            url={`https://vote.actionsa.org.za`}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              aria-hidden="true"
            >
              <path
                d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                fill="currentColor"
              ></path>
            </svg>
          </WhatsappShareButton>
          <div className="my-6">
            <Button
              size="xl"
              variant="primary"
              colorScheme="green"
              onClick={() => {
                logout()
              }}
            >
              Log out
            </Button>
          </div>
        </>
      )}
    </LayoutBox>
  )
}
