import { FC, useState } from 'react'
import { navigate } from '@reach/router'
import { Box, Button, Loader, Modal } from '@plusplusminus/plusplusdash'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Alert } from '../../../components/Alert'
import { useMemo } from 'react'
import { BallotSuccess } from './BallotSuccess'
import { useCallback } from 'react'

function shuffle(array: any[]) {
  let m = array.length,
    t,
    i

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--)

    // And swap it with the current element.
    t = array[m]
    array[m] = array[i]
    array[i] = t
  }

  return array
}

export const StationBallotList: FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <div className="py-10 px-6 sm:px-10 lg:col-span-1 xl:p-12 h-650" style={{ minHeight: '600px' }}>
            <Alert color="red" title="Error" message={'Voting Closed'} />
            <div className="my-6">
              <Button
                size="xl"
                variant="primary"
                colorScheme="green"
                onClick={() => {
                  navigate('/logout')
                }}
              >
                Log out
              </Button>
            </div>
            <div className="mt-8">
              <dt className="text-sm font-medium text-gray-900">Need assistance?</dt>
              <dd className="text-sm text-gray-500">
                Email <a href="mailto:vote@actionsa.org.za">vote@actionsa.org.za</a>.
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BALLOT_QUERY = gql`
  query BallotsQuery {
    voted
    hasIDBook
    ballots {
      id
      status
      type
      location {
        name
        code
        municipality {
          name
        }
      }
      candidates {
        id
        owner {
          firstName
          lastName
        }
      }
    }
  }
`

const SUBMIT_VOTE = gql`
  mutation createVote($input: [VoteInput!]!) {
    createVote(input: $input) {
      message
      redirect
    }
  }
`
