import React from 'react'
import { Layout } from '../../Layout'
import { HeaderWithActions } from '../../Header/HeaderWithActions'
import { ProfileDetails } from '../../../pages/Profile/ProfileDetails'
import { AddressDetails } from '../../../pages/Profile/AddressDetails'
import { useUserQuery } from '../../../hooks/useUserQuery'
import { Box } from '@plusplusminus/plusplusdash'
import { useParams } from '@reach/router'
import { RoleDetails } from './RoleDetails'

export const EditUser: React.FC = () => {
  const { id } = useParams()
  const { loading, data } = useUserQuery(id)
  const { loading: meLoading, data: meData } = useUserQuery()
  if (loading || meLoading) return null

  const fullName = `${data.userById.firstName} ${data.userById.lastName}`
  const hasEditPermission = meData.me && meData.me.role === 'ADMIN'
  return (
    <Layout>
      <Box className="mb-4">
        <HeaderWithActions title={fullName} actions={[]} />
      </Box>
      <Box className="mb-6 mx-6">
        <ProfileDetails
          data={data.userById}
          hasEditPermission={hasEditPermission}
          isUser={false}
          role={meData.me.role}
        />

        {hasEditPermission && <RoleDetails data={data.userById} />}
        <AddressDetails id={data.userById.id} data={data.userById.address} isUser={false} />
      </Box>
    </Layout>
  )
}
