import { gql, useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

export type useAddressSearch = ReturnType<typeof useVoterLoginByOtp>

export const useVoterLoginByOtp = ({ onSuccess, onError }: any) => {
  const form = useForm()
  const [load, setLoading] = useState(false)
  const [login, { loading }] = useMutation(LOGIN_USER)

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true)

        //validation
        const result = await login({
          variables: {
            idnumber: data.idnumber.trim(),
            otp: data.otp.trim()
          }
        })

        onSuccess(result)
        setLoading(false)
      } catch (err) {
        form.setError('idnumber', { message: err.message })
        onError(err)
        setLoading(false)
      }
    },
    [login, onSuccess, onError, form]
  )

  return {
    ...form,
    onSubmit,
    submitting: loading || load
  }
}

const LOGIN_USER = gql`
  mutation loginUser($idnumber: String, $otp: String) {
    login: loginByIdNumberVoter(idnumber: $idnumber, otp: $otp) {
      message
      token
      redirect
    }
  }
`
