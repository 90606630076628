import React from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'

interface IAdminPrivateRoute extends RouteComponentProps {
  component: React.ElementType
  roles?: Array<string>
}

const AdminPrivateRoute: React.FC<IAdminPrivateRoute> = ({ component: Component, roles = ['ADMIN'], ...props }) => {
  const { loading, data, error } = useUserQuery()

  if (loading) return <div>Loading</div>

  if (error) return <Redirect noThrow to={'/login'} />

  const hasAccess = data.me && roles.includes(data.me.role)

  return hasAccess ? <Component {...props} {...data.me} /> : <Redirect noThrow to={'/login'} />
}

export { AdminPrivateRoute }
