import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Box, Table, Loader, Alert } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'


interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  role: string
}

interface UserListItems {
  items: Array<User>
  newTab?: boolean
}

const TABLE_SHAPE = [
  { label: 'First Name', key: 'firstName', isSortable: true },
  { label: 'Last Name', key: 'lastName', isSortable: true },
  { label: 'Email', key: 'email', isSortable: true },
  { label: 'Role', key: 'roles', isSortable: true },
  { label: 'Edit', key: 'edit', isSortable: true },
]

export const UserList: React.FC<UserListItems> = ({ items }) => {
  return (

    <Table shape={TABLE_SHAPE}>
      {items.map((user: any) => {
        return (
          <Table.Row>
            <Table.Cell>{user.firstName}</Table.Cell>
            <Table.Cell>{user.lastName}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.role}</Table.Cell>
            <Table.Cell> <Link to={`/users/${user.id}`} className="truncate hover:text-gray-600">
              <span>Edit</span>
            </Link></Table.Cell>
          </Table.Row>
        )
      })}
    </Table>

  )
}
