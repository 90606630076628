import { gql, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'

interface Props {
  token?: string
}

export const ResendOtp: React.FC<Props> = ({ token }) => {
  const [show, setShow] = useState(true)

  const [resend, { loading: resendLoading }] = useMutation(RESEND_OTP)

  useEffect(() => {
    setShow(true)
    setTimeout(function () {
      setShow(false)
    }, 30000)
  }, [token, resendLoading])

  return (
    <button
      disabled={show || resendLoading}
      onClick={() => resend({ variables: { token: token } })}
      className={`ml-4 mt-2 whitespace-nowrap text-base font-medium text-${
        !show ? 'indigo-500' : 'gray-200'
      } hover:text-${!show ? 'indigo-500' : 'gray-200'}`}
    >
      Resend OTP
    </button>
  )
}

const RESEND_OTP = gql`
  mutation resendOtp($token: String!) {
    resendOtp(id: $token) {
      message
    }
  }
`
