import { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from '../../components/Layout'
import { Applications } from '../../components/Application'

export const Dashboard: FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div className="px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Home</h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4"></div>
        </div>
        <Applications />
      </main>
    </Layout>
  )
}
