import { gql, useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../services/validate'

export type useAddressSearch = ReturnType<typeof useLogin>

export const useLogin = ({ onSuccess, onError }: any) => {
  const form = useForm()
  const [load, setLoading] = useState(false)
  const [login, { loading }] = useMutation(LOGIN_USER)

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true)
        const contact = new InputValidator(data.contact)
        const idnumber = new InputValidator(data.idnumber)
        const isEmail = contact.validateEmail()
        const isCellphone = contact.validateCellphone()
        const isIdnumber = idnumber.validateIdNumber()

        if (!isIdnumber) {
          form.setError('idnumber', { message: 'Invalid ID Number', type: 'manual' })
          setLoading(false)
          return
        }

        if (!isCellphone && !isEmail) {
          form.setError('contact', { message: 'Invalid email or contact number', type: 'manual' })
          setLoading(false)
          return
        }

        const dto = {
          email: isEmail ? contact.input : '',
          cellphone: isCellphone ? contact.formatCellphone('E.164') : '',
          idnumber: data.idnumber
        }

        const result = await login({
          variables: dto
        })

        onSuccess(result)
        setLoading(false)
      } catch (err) {
        form.setError('idnumber', { message: err.message })
        onError()
        setLoading(false)
      }
    },
    [login, onSuccess, onError, form]
  )

  return {
    ...form,
    onSubmit,
    submitting: loading || load
  }
}

const LOGIN_USER = gql`
  mutation loginUser($idnumber: String!, $cellphone: String, $email: String) {
    login: loginByContact(idnumber: $idnumber, cellphone: $cellphone, email: $email) {
      message
      token
      redirect
    }
  }
`
