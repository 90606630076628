import React, { useState } from 'react'
import { Input, Button, Box, Grid, Form } from '@plusplusminus/plusplusdash'

interface Props {
  refetch: any
}
export interface Input {
  status?: string | null
  firstName?: string
  lastName?: string
  idnumber?: string
  locationId?: string
  muncipalityId?: string
  provinceId?: string
  wardId?: string
  applicationType?: string
}

export const UserSearch: React.FC<Props> = ({ refetch }) => {
  const [input, setInput] = useState<Input>({})

  const handleSubmit = (e: any) => {
    e.preventDefault()
    refetch({ input })
  }

  const handleInputChange = (event: any) => {
    const target = event.target
    const name = target.name
    const value = target.value
    setInput({ ...input, [name]: value })
  }

  return (
    <>
      <Box className="mx-2">
        <Form className="flex flex-col mb-2" onSubmit={handleSubmit}>
          <Box className="mb-2">
            <Grid columns={3} rows={1} gap={2} className="mb-2">
              <Box className="lex flex-wrap content-start">
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  FIRST NAME
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  onChange={handleInputChange}
                  value={input?.firstName || ''}
                />
              </Box>
              <Box>
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  LAST NAME
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  onChange={handleInputChange}
                  value={input?.lastName || ''}
                />
              </Box>
              <Box>
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  ID NUMBER
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="idnumber"
                  placeholder="ID Number"
                  type="text"
                  onChange={handleInputChange}
                  value={input?.idnumber || ''}
                />
              </Box>
            </Grid>
          </Box>
          <Button variant="primary" className="w-40 mb-2" colorScheme="green" type="submit">
            Search
          </Button>
        </Form>
      </Box>
    </>
  )
}
