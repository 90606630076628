import { FC, useState, useEffect } from 'react'
import { RouteComponentProps, Link, useLocation } from '@reach/router'
import { Box, Button, Input, Form, Loader } from '@plusplusminus/plusplusdash'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Layout } from '../../components/Layout'
import { InputValidator } from '../../services/validate'
import { useForm } from 'react-hook-form'
import { Alert } from '../../components/Alert'
import { ListLeftRight } from '../../components/List/ListLeftRight'
import { DownloadFile } from '../../components/Application/ ui/DownloadFile'
import { parse } from 'query-string'
import { WardSelector } from './WardSelector'

export const VerifyVoterContainer: FC<RouteComponentProps> = () => {
  const location = useLocation()
  const searchParams = parse(location.search)
  const form = useForm()
  const [error, setError] = useState<string>('')
  const [hide, setHide] = useState<boolean>(false) //Dont know how to clear the data of the query.
  const [otp, setOtp] = useState<string>('')

  const [loadUser, { loading, data }] = useLazyQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.voter?.message === 'found') {
        setHide(false)
      } else if (res?.voter?.message === 'not-found') {
        setOtp('')
        setError('')
        setHide(true)
      }
    },
    onError: (err) => {
      console.log(err)
      setError('There has been a problem. Please contact support or try again later.')
    }
  })
  const [generateOtp, { loading: otpLoader }] = useMutation(GET_OTP, {
    onCompleted: (res) => {
      if (res?.generateVoterOtp) {
        setOtp(res.generateVoterOtp)
      }
    },
    onError: (err) => {
      if (err) {
        setError(err.message)
      }
    }
  })

  useEffect(() => {
    if (searchParams?.idnumber && !hide) {
      loadUser({
        variables: {
          idnumber: searchParams.idnumber
        }
      })
    } else {
      reset()
    }
  }, [])

  const handleSubmit = (e: any) => {
    setError('')
    form.clearErrors()
    setOtp('')
    const { idnumber } = form.getValues()
    const id = new InputValidator(idnumber)
    const isIdnumber = id.validateIdNumber()

    if (!isIdnumber) {
      form.setError('idnumber', { message: 'Invalid ID Number', type: 'manual' })
      return
    }
    loadUser({
      variables: {
        idnumber: idnumber
      }
    })
  }

  const reset = () => {
    form.clearErrors()
    form.reset()
    setOtp('')
    setError('')
    setHide(true)
  }

  return (
    <Layout showVoterMenu={true}>
      <Box className="flex flex-col">
        <Box className="p-5 pb-0">
          <h1 className="text-lg">Verify voter</h1>
        </Box>
        <Box className="mx-2 p-5">
          <Form className="flex flex-col mb-2" onSubmit={form.handleSubmit(handleSubmit)}>
            <Box className="mb-2">
              <Box className="lex flex-wrap content-start">
                <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
                  ID number
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  name="idnumber"
                  placeholder="South African identification number"
                  type="text"
                  ref={form.register({ required: true })}
                  defaultValue={searchParams?.idnumber && !hide ? searchParams?.idnumber : ''}
                />
              </Box>
            </Box>
            {form.errors.idnumber && form.errors.idnumber.type === 'required' ? (
              <div className="text-sm text-red-300 mb-2">Required Field</div>
            ) : null}
            {error && (
              <Box className="mb-6">
                <Alert color="red" title="Error" message={error} />
              </Box>
            )}
            <Button variant="primary" className="w-40 mb-2" colorScheme="green" type="submit">
              {loading ? 'Loading...' : 'Search'}
            </Button>
            <Button variant="primary" className="w-40 mb-2 mr-2" colorScheme="green" onClick={() => reset()}>
              Reset
            </Button>
          </Form>

          {data?.voter?.message === 'not-found' && (
            <Box>
              <div className="flex flex-auto justify-center items-center mb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Voter not found.</h3>
              </div>
              <div className="flex flex-auto justify-center items-center">
                <Link to={`/voter/registration?idnumber=${form.getValues()?.idnumber || ''}`}>
                  <Button variant="primary" className="mb-2 text-center" colorScheme="green" type="submit">
                    Register Voter
                  </Button>
                </Link>
              </div>
            </Box>
          )}

          {data?.voter?.message === 'found' && !hide ? (
            <div className="bg-white  overflow-hidden">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Voter Information</h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <ListLeftRight content={`${data?.voter?.voter?.idnumber || ''}`} title={'ID number'} />
                  <ListLeftRight content={`${data?.voter?.voter?.firstName || ''}`} title={'First Name'} />
                  <ListLeftRight content={`${data?.voter?.voter?.lastName || ''}`} title={'Last Name'} />
                  <ListLeftRight content={`${data?.voter?.voter?.cellphone || ''}`} title={'Cellphone'} />
                  <ListLeftRight
                    content={`${data?.voter?.voter?.status === 'Deceased' ? 'Yes' : 'No'}`}
                    title={'Deceased'}
                  />
                  {data?.voter?.voter?.currentWardId && <ListLeftRight content={`${data.voter.voter.currentWardId}`} title={'Current Ward'} />}
                  <ListLeftRight content={`${data?.voter?.voter?.ward || ''}`} title={'Ward'} />
                  <ListLeftRight content={`${data?.voter?.voter?.municipality || ''}`} title={'Municipality'} />
                  <ListLeftRight
                    content={<WardSelector structure="WARD" userId={data?.voter?.voter.id} />}
                    title={'Select new registered ward'}
                  />
                  <ListLeftRight
                    content={`${typeof data === 'undefined' ? '' : data?.voter?.voter?.voted ? 'Yes' : 'No'}`}
                    title={'Already voted'}
                  />
                  <ListLeftRight content={`${data?.voter?.voter?.status || ''}`} title={'Status'} />
                  {!data?.voter?.voter?.voted ? (
                    <ListLeftRight
                      content={
                        <>
                          <Button
                            variant="primary"
                            className="w-40 mb-2 mr-2"
                            colorScheme="green"
                            disabled={data?.voter?.voter?.voted ? true : false}
                            onClick={() => {
                              if (!data || data?.voter?.message === 'not-found') {
                                setError('No user. Please search user before generating OTP.')
                              } else {
                                generateOtp({
                                  variables: {
                                    id: data?.voter?.voter?.id
                                  }
                                })
                              }
                            }}
                          >
                            {otpLoader ? 'Working...' : 'Generate 6 Digit Pin'}
                          </Button>
                          {otp && (
                            <Box className="flex flex-row">
                              <h3 className="text-lg font-medium text-gray-900 mr-2">{`6 Digit Pin:`}</h3>
                              <h3 className="text-xl font-medium text-blue">{`${otp}`}</h3>
                            </Box>
                          )}
                        </>
                      }
                      title={'Generate 6 Digit Pin'}
                    />
                  ) : null}
                </dl>
              </div>

              {data?.voter?.voter?.file && (
                <>
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Documents</h3>
                  </div>
                  <ListLeftRight
                    content={
                      <DownloadFile applicationId={data?.voter?.voter?.applicationId} file={data?.voter?.voter?.file} />
                    }
                    title={'ID document'}
                  />
                </>
              )}
              <div className="px-4 py-5 sm:px-6 ">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Ballot Information</h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <ListLeftRight
                    content={`${typeof data === 'undefined' ? '' : data?.voter?.voter?.wardBallot ? 'Yes' : 'No'}`}
                    title={'Ward'}
                  />
                  <ListLeftRight
                    content={`${typeof data === 'undefined' ? '' : data?.voter?.voter?.muniBallot ? 'Yes' : 'No'}`}
                    title={'Mayor'}
                  />
                </dl>
              </div>
            </div>
          ) : null}
        </Box>
      </Box>
    </Layout>
  )
}

const USER_QUERY = gql`
  query getVoter($idnumber: String!) {
    voter(idnumber: $idnumber) {
      message
      voter {
        id
        idnumber
        firstName
        lastName
        cellphone
        ward
        municipality
        voted
        status
        wardBallot
        muniBallot
        file
        applicationId
        currentWardId
      }
    }
  }
`

const GET_OTP = gql`
  mutation otp($id: String!) {
    generateVoterOtp(id: $id)
  }
`
