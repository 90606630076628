import React, { useState } from 'react'
import { Box, Button, Modal } from '@plusplusminus/plusplusdash'
import { ListLeftRight } from '../../List/ListLeftRight'
import { Alert } from '../../Alert'

interface RegisterButtonProps {
  applicationId: string
  changeStatus: any
  changeStatusLoading: boolean
  status: string
  response: any
  error: any
}

export const WithdrawApplication: React.FC<RegisterButtonProps> = ({
  applicationId,
  changeStatus,
  changeStatusLoading,
  status,
  error,
  response
}) => {
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false)

  return (
    <>
      {showConfirmWithdraw ? (
        <Modal isOpen={showConfirmWithdraw} onClose={() => setShowConfirmWithdraw(!showConfirmWithdraw)}>
          <Box>
            {response && !error ? (
              <>
                <Alert color="green" title="Success" message="Application withdrawn" />
                <Button
                  type="button"
                  className="m-2"
                  variant="primary"
                  colorScheme="black"
                  onClick={() => setShowConfirmWithdraw(!showConfirmWithdraw)}
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                {error ? (
                  <>
                    <Alert color="red" title="Error" message="Something went wrong please try again later." />
                    <Button
                      type="button"
                      className="m-2"
                      variant="primary"
                      colorScheme="black"
                      onClick={() => setShowConfirmWithdraw(!showConfirmWithdraw)}
                    >
                      Close
                    </Button>
                  </>
                ) : (
                  <>
                    <Box className="sm:mx-auto sm:w-full sm:max-w-md">
                      <h3 className="mt-6 text-center text-3xl font-bold text-red">
                        Are you sure you want to withdraw the application?
                      </h3>
                    </Box>
                    <Box className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                      <Button
                        type="button"
                        variant="primary"
                        colorScheme="red"
                        className="ml-2"
                        onClick={() =>
                          changeStatus({
                            variables: {
                              applicationId,
                              status: 'withdrawn'
                            }
                          })
                        }
                      >
                        {changeStatusLoading ? 'Loading' : 'Withdraw'}
                      </Button>
                      <Button
                        type="button"
                        variant="primary"
                        className="mr-2 float-right"
                        onClick={() => setShowConfirmWithdraw(!showConfirmWithdraw)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Modal>
      ) : null}
      <ListLeftRight
        content={
          <Button
            disabled={status === 'withdrawn'}
            type="button"
            variant="primary"
            colorScheme={status === 'withdrawn' ? 'gray' : 'black'}
            onClick={() => setShowConfirmWithdraw(!showConfirmWithdraw)}
          >
            Withdraw application
          </Button>
        }
        title={`Withdraw application ${status === 'withdrawn' ? '(withdrawn)' : ''}`}
      />
    </>
  )
}
