import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Box, Table, Loader, Alert } from '@plusplusminus/plusplusdash'
import { Layout } from '../Layout'
import { UserList } from './ui/UsersList'
import { UserSearch } from './ui/UserSearch'
import { usePagination } from '../../hooks/usePagaination'

export const UserContainer: React.FC = () => {
  const [page, offset, limit, nextPage, prevPage] = usePagination(50)
  const { data, loading, error, refetch, previousData } = useQuery(USERS_QUERY, {
    variables: {
      pagination: { limit, sort: 'ASC', page },
      input: {}
    }
  })

  // if (loading) return null

  const tableData = data || previousData

  return (<Layout>
    <Box className="flex flex-col">
      <Box className="p-5 pb-0">
        <h1 className="text-lg">Users</h1>
        <UserSearch refetch={refetch} />
      </Box>
      {error ? <Alert type="error">
        <Alert.Heading>There was an error fetching users</Alert.Heading>
      </Alert> : !previousData && loading ? (
        <div className="flex flex-auto justify-center items-center">
          <Loader isActive={true} />
        </div>
      ) : tableData ? <Box><UserList items={data?.searchUsers?.docs || []} /><div className="flex justify-end py-4 px-8">
        <div className="w-16">
          {tableData.searchUsers.hasPrevPage && (
            <a className="underline text-md cursor-pointer" onClick={() => prevPage()}>
              Prev
            </a>
          )}
        </div>
        <span className="w-16 text-md mx-4">Page {page}</span>
        <div className="w-16">
          {tableData.searchUsers.hasNextPage && (
            <a className="underline text-md cursor-pointer" onClick={() => nextPage()}>
              Next
            </a>
          )}
        </div>
      </div></Box> : null}

    </Box>
  </Layout>)
}


const USERS_QUERY = gql`
query SearchUsers($input: SearchUserInput,  $pagination: PaginationArgs!){
  searchUsers(input: $input, pagination: $pagination){
    docs{
      id
      firstName
      lastName
      email
      idnumber
      role
    }
    totalDocs
    hasPrevPage
    hasNextPage
  }
}
`