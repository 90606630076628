import React from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'

interface IPrivateRoute extends RouteComponentProps {
  component: React.ElementType
}

const PrivateRoute: React.FC<IPrivateRoute> = ({ component: Component, ...props }) => {
  const { loading, data, error } = useUserQuery()

  if (loading) return <div>Loading</div>

  if (error) return <Redirect noThrow to={'/login'} />

  return data.me ? <Component {...props} /> : <Redirect noThrow to={'/login'} />
}

export { PrivateRoute }
