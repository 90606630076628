import React, { FC, useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { LayoutBox } from '../../components/LayoutBox'
import { useVoterLoginByOtp } from './hooks/useVoterLoginIdNumber'
import { Alert } from '../../components/Alert'

interface LoginFormProps {
  form?: any
  token?: any
  error: string
}

export const StationContainer: FC<RouteComponentProps> = () => {
  const navigate = useNavigate()

  const [error, setError] = useState('')

  const otpIdNumberForm = useVoterLoginByOtp({
    onSuccess: () => {
      setError('')
      navigate('/voter/ballot')
    },
    onError: (error: any) => {
      setError(error.message)
    }
  })

  return (
    <LayoutBox
      title="Thank you for participating in the ActionSA Candidate Elections."
      description={
        'Please enter your ID Number and Pin to cast your vote for your preferred Ward Councillor and Mayoral candidate.'
      }
      formTitle={''}
      showLogo={true}
    >
      <LoginOtpForm form={otpIdNumberForm} error={error} />
    </LayoutBox>
  )
}

export const LoginOtpForm: React.FC<LoginFormProps> = (props) => {
  const { register, handleSubmit, errors, onSubmit, submitting } = props.form

  return (
    <form autoComplete="no" onSubmit={handleSubmit(onSubmit)}>
      {props.error ? <Alert title={'Error'} color="red" message={props.error} action={undefined}></Alert> : null}
      <p className="my-4 text-base max-w-3xl">Enter your ID Number and Pin Provided.</p>
      <Box className="mb-4">
        <label htmlFor={'contact'} className="block text-sm font-medium text-gray-900 mb-1">
          South African ID Number
        </label>
        <Input
          as="input"
          variant="standard"
          width="md"
          name="idnumber"
          placeholder="Enter your ID number"
          ref={register({ required: true })}
          className="mb-2 w-full"
        />
        {errors.idnumber && <div className="text-sm text-red-300">{errors.idnumber.message}</div>}
      </Box>
      <Box className="mb-4">
        <label htmlFor={'otp'} className="block text-sm font-medium text-gray-900 mb-1">
          6 Digit Pin
        </label>
        <Input
          as="input"
          variant="standard"
          width="md"
          name="otp"
          placeholder="Enter your 6 digit pin"
          ref={register({ required: true })}
          className="mb-2 w-full"
        />
        {errors.otp && <div className="text-sm text-red-300">{errors.otp.message}</div>}
      </Box>
      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <Button size="xl" variant="primary" type="submit" colorScheme="green" className="mt-2 " disabled={submitting}>
          {submitting ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}
