import React, { useState } from 'react'
import { Table, Box, Alert } from '@plusplusminus/plusplusdash'
import saveFile from 'save-as-file'

const reportParams: any = {
  prCandidates: ['pdcandidate'],
  voterApplications: ['Voter'],
  mayor: ['mayor'],
  wardCandidate: ['candidate']
}

const TABLE_SHAPE = [
  { label: 'Report', key: 'report', isSortable: true },
  { label: 'Actions', key: 'download', isSortable: true }
]

export const ReportList: React.FC = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const downloadReport = async (reportType: string, reportName?: string, params?: any) => {
    setLoading(true)
    setSuccess(false)

    let query: Array<string> = []

    if (params && Object.keys(params).length) {
      Object.keys(params).forEach((r) => {
        if (r === 'roles') {
          query = params[r].map((element: string, index: number) => {
            return `roles[${index}]=${element}`
          })
        }

        if (r === 'role') {
          query = [...query, `role=${params[r]}`]
        }

        if (r === 'muni') {
          query = [...query, `muni=${params[r]}`]
        }

        if (r === 'template') {
          query = [...query, `template=${params[r]}`]
        }
      })
    }
    const report = reportName ? reportName : reportType
    const date = new Date()
    const formattedDate = date.toISOString().split('T')[0]
    const url = `${process.env.REACT_APP_API}/reports/${reportType}/?${query.join('&')}`
    const fileName = `${report}-${formattedDate}.xlsx`

    await window
      .fetch(url, {
        method: 'GET',
        credentials: 'include'
      })
      .then((response) => {
        if (response.ok) {
          setError('')
          setSuccess(true)
          // return response.blob()
        } else {
          setError(response.statusText)
        }
      })
      .then((result: any) => {
        setLoading(false)
        if (result) {
          saveFile(result, fileName)
        }
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }

  return (
    <Box>
      {error && (
        <Alert type="error">
          <Alert.Heading>There was an error downloading report</Alert.Heading>
        </Alert>
      )}
      {success && (
        <Alert type="success">
          <Alert.Heading>A download link will be emailed to you when report is finished processing.</Alert.Heading>
        </Alert>
      )}
      <Table shape={TABLE_SHAPE}>
        <Table.Row>
          <Table.Cell>Ward Candidates</Table.Cell>
          <Table.Cell>
            <button
              onClick={() => downloadReport('candidates', 'candidates', { roles: reportParams.wardCandidate })}
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Mayor Candidates</Table.Cell>
          <Table.Cell>
            <button
              onClick={() => downloadReport('candidates', 'candidates', { roles: reportParams.mayor })}
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>PR Candidates</Table.Cell>
          <Table.Cell>
            <button
              onClick={() => downloadReport('candidates', 'candidates', { roles: reportParams.prCandidates })}
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>PR Candidates</Table.Cell>
          <Table.Cell>
            <button
              onClick={() => downloadReport('candidates', 'pr-candidates', { roles: reportParams.prcandidates })}
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request Report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Mayor Candidates</Table.Cell>
          <Table.Cell>
            <button
              onClick={() => downloadReport('candidates', 'mayor-candidates', { roles: reportParams.mayor })}
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request Report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>EKU Petitions</Table.Cell>
          <Table.Cell>
            <button onClick={() => downloadReport('petitions', 'petitions-eku', { muni: 'EKU' })} disabled={loading}>
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>JHB Petitions</Table.Cell>
          <Table.Cell>
            <button onClick={() => downloadReport('petitions', 'petitions-jhb', { muni: 'JHB' })} disabled={loading}>
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>TSH Petitions</Table.Cell>
          <Table.Cell>
            <button onClick={() => downloadReport('petitions', 'petitions-tsh', { muni: 'TSH' })} disabled={loading}>
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>All ward candidates applications</Table.Cell>
          <Table.Cell>
            <button
              onClick={() =>
                downloadReport('applicationReport', 'candidates-applications', {
                  roles: reportParams.wardCandidate
                })
              }
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>All PR candidates applications</Table.Cell>
          <Table.Cell>
            <button
              onClick={() =>
                downloadReport('applicationReport', 'candidates-applications', {
                  roles: reportParams.prCandidates
                })
              }
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>All mayor candidates applications</Table.Cell>
          <Table.Cell>
            <button
              onClick={() =>
                downloadReport('applicationReport', 'candidates-applications', {
                  roles: reportParams.mayor
                })
              }
              disabled={loading}
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>All voter applications</Table.Cell>
          <Table.Cell>
            <button
              disabled={loading}
              onClick={() =>
                downloadReport('applicationReport', 'voter-applications', { roles: reportParams.voterApplications })
              }
            >
              {loading ? 'Loading' : 'Request report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Ward Candidates Requirement report</Table.Cell>
          <Table.Cell>
            <button
              disabled={loading}
              onClick={() => downloadReport('requirement', 'ward-candidate-requirements', { role: 'candidate' })}
            >
              {loading ? 'Loading' : 'Request Report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>PR Candidates Requirement report</Table.Cell>
          <Table.Cell>
            <button
              disabled={loading}
              onClick={() => downloadReport('requirement', 'pr-candidate-requirements', { role: 'pdcandidate' })}
            >
              {loading ? 'Loading' : 'Request Report'}
            </button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Voter Requirement report</Table.Cell>
          <Table.Cell>
            <button
              disabled={loading}
              onClick={() => downloadReport('requirement', 'voter-requirements', { role: 'Voter' })}
            >
              {loading ? 'Loading' : 'Request Report'}
            </button>
          </Table.Cell>
        </Table.Row>
      </Table>
    </Box>
  )
}
