import { gql, useMutation } from '@apollo/client'
import { Box, Button } from '@plusplusminus/plusplusdash'
import React, { useState } from 'react'
import { StructureComponent } from '../../components/Structures/ui/StructureComponent'
import { Alert } from '../../components/Alert'

type StructureComponentProps = {
  structure: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  filter: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  level: number
  resets?: Array<'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'>
}

type StructureForm = {
  COUNTRY: string
  PROVINCE: string
  MUNICIPALITY: string
  WARD: string
}

interface ContainerProps {
  userId: string
  structure: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  refetch?: () => void
  renderSubmit?: (form: StructureForm) => React.ReactNode
}

export const WardSelector: React.FC<ContainerProps> = (props) => {
  const [form, setForm] = useState({
    COUNTRY: 'ZA',
    WARD: '',
    MUNICIPALITY: '',
    PROVINCE: 'GP'
  })

  const [message, setMessage] = useState('')
  const [addCandidate] = useMutation(ADD_USER_LOCATION, {
    onCompleted: () => {
      setMessage('Voter ward updated.')
    }
  })

  const components: Array<StructureComponentProps> = [
    {
      structure: 'PROVINCE',
      level: 6,
      filter: 'COUNTRY',
      resets: ['MUNICIPALITY', 'WARD']
    },
    {
      structure: 'MUNICIPALITY',
      level: 5,
      filter: 'PROVINCE',
      resets: ['WARD']
    },
    {
      structure: 'WARD',
      level: 4,
      filter: 'MUNICIPALITY',
      resets: []
    }
  ]

  const structureLevel = props.structure ? components.find((c) => c.structure === props.structure)?.level ?? 0 : 0

  return (
    <>
      <Box className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mb-4">
        {components
          .filter((c) => c.level >= structureLevel)
          .map((component) => {
            return (
              <Box className="sm:col-span-2">
                <StructureComponent
                  shouldFilter={true}
                  label={component.structure}
                  structure={component.structure}
                  type={component.filter}
                  code={form[component.filter]}
                  value={form[component.structure]}
                  onStructure={(id: string) =>
                    setForm({
                      ...form,
                      [component.structure]: id,
                      ...component.resets?.reduce((result, item) => {
                        result[item] = ''
                        return result
                      }, {} as StructureForm)
                    })
                  }
                />
              </Box>
            )
          })}
        {props.renderSubmit ? props.renderSubmit(form) : null}
      </Box>
      <Button
        size="xl"
        variant="primary"
        disabled={!form[props.structure]}
        onClick={async () => {
          await addCandidate({
            variables: {
              userId: props.userId,
              locationId: form[props.structure]
            }
          })
          props.refetch && props.refetch()
        }}
      >
        Update
      </Button>
      {message ? <Alert color="green" action={undefined} title="Ward Updated" message={message}></Alert> : null}
    </>
  )
}

const ADD_USER_LOCATION = gql`
  mutation updateLocation($locationId: String!, $userId: String!) {
    setWardLocation(locationId: $locationId, userId: $userId)
  }
`
